import {useState, useEffect} from 'react'
import {
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    Container,
    Box,
    TextField,
    Button,
    Grid,
    Typography,
    Paper,
    Link
} from '@mui/material'
import {
    LoadingButton
} from '@mui/lab'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import AuthService from '../services/AuthService'

function RestorePassword(props) {
    const [password, setPassword] = useState('')
    const [re_password, setRePassword] = useState('')
    const [errors, setErrors] = useState({})
    const [requesting, setRequesting] = useState(false)
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    let { code } = useParams()

    useEffect(() => {
        resetError()
    }, [])

    function handlePassword(e) {
        setPassword(e.target.value)
        resetError()
    }
    function handleRePassword(e) {
        setRePassword(e.target.value)
        resetError()
    }

    function resetError() {
        setErrors({
            password: false,
            password_message: '',
            re_password: false,
            re_password_message: '',
        })
    }

    async function restorePassword() {
        if( password !== re_password ) {
            setErrors({
                ...errors,
                re_password: true,
                re_password_message: 'Passwords do not match'
            })
            return
        }
        if( !password && !re_password ) {
            setErrors({
                ...errors,
                password: true,
                password_message: 'Password required'
            })
            return
        }
        setRequesting(true)
        try {
            const _request = await AuthService.resetPassword({
                code,
                password,
                re_password
            })
            if (_request.result) {
                setSuccess(true)
            }
            setRequesting(false)
        } catch (e) {
            setErrors({
                ...errors,
                email_message: e.response?.data.message
            })
            setRequesting(false)
        }
    }

    return <>
        <Container fixed>
            <Grid container sx={{pt: 20}}>
                <Grid item xs={0} sm={2} lg={3}></Grid>
                <Grid item xs={12} sm={8} lg={6}>
                    <Paper sx={{p: 3, pb:4}}>
                        <Typography variant="h5">
                            Retail ORBIT®
                        </Typography>
                        {!success && (
                            <>
                                <Typography variant="subtitle1" sx={{mb:3}}>
                                    Reset your password
                                </Typography>
                                <form>
                                    <TextField
                                        fullWidth
                                        label="New Password"
                                        type='password'
                                        value={password}
                                        name="password"
                                        onChange={handlePassword}
                                        error={errors.password}
                                        helperText={errors.password_message}
                                        sx={{mt: 2, backgroundColor: 'white'}}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Repeat Password"
                                        type='password'
                                        value={re_password}
                                        name="password"
                                        onChange={handleRePassword}
                                        error={errors.re_password}
                                        helperText={errors.re_password_message}
                                        sx={{mt: 2, backgroundColor: 'white'}}
                                    />
                                    <LoadingButton
                                        variant="contained"
                                        loading={requesting}
                                        color='primary'
                                        sx={{mt: 2, mb: 2}}
                                        fullWidth
                                        size="large"
                                        onClick={restorePassword}
                                        startIcon={<ExitToAppRoundedIcon />}
                                    >
                                        Reset my password
                                    </LoadingButton>
                                </form>
                            </>
                        )}
                        {success && (
                            <>
                                <Typography variant="subtitle1" sx={{mb:3}}>
                                    Your password has been successfully reset!
                                </Typography>
                                <Link
                                    href="/login"
                                    underline="hover"
                                    sx={{ textTransform: 'uppercase'}}
                                >
                                    Go to login
                                </Link>
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    </>
}

export default RestorePassword