import { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Button,
	IconButton,
	Table,
	TableRow,
	TableContainer,
	TableCell,
	TableBody,
	TableHead,
	Chip,
	CircularProgress,
} from '@mui/material';
import {
	Navigate,
	useParams,
	Link,
} from "react-router-dom";
import SectionTitle from '../../components/common/SectionTitle'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import _ from 'underscore';
import PlanService from '../../services/PlanService';

function CopyPlan(props) {

	const [plans, setPlans] = useState(null)
	const [planFiles, setPlanFiles] = useState(null)

	useEffect(() => {
		loadPlans();
		loadPlanFiles();
	}, [])

	const loadPlans = async() => {
		try {
			const data = await PlanService.getCopyPlans()
			setPlans(data)
		} catch(e) {
		}
	}

	const loadPlanFiles = async() => {
		try {
			const data = await PlanService.getCopyPlanFiles()
			setPlanFiles(data)
		} catch(e) {
		}
	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">Copy Plan</Typography>
		<Grid container spacing={2} sx={{mt: 1}}>
			<Grid item xs={12} sm={6}>
				<Paper elevation={2}>
					<SectionTitle 
						label='Processed Files'
						rightComponent={
							<>
								<Box component={Link} to={`/plan/copy/upload`}>
									<Button color="primary" variant="contained" startIcon={<UploadFileOutlinedIcon />}>Upload File</Button>
								</Box>
							</>
						}
					/>
					<Box sx={css.tableSection}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Status</TableCell>
										<TableCell>File Name</TableCell>
										<TableCell>Created Date</TableCell>
										<TableCell>Processed Date</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{_.isNull(planFiles) && <TableRow>
									<TableCell colSpan={5} sx={css.loadingSection}>
										<CircularProgress />
									</TableCell>
								</TableRow>}
								{planFiles?.map(item => (
									<TableRow hover key={`file_item${item.id}`}>
										<TableCell>
										{(item.error_plans_count > 0 || item.error) && 
											<Chip size="small" color="error" label="Errors" variant="contained"/>
										}
										{item.error_plans_count == 0 && !item.error && 
											<Chip size="small" color="success" label="Complete" variant="outlined"/>
										}
										</TableCell>
										<TableCell>{item.filename}</TableCell>
										<TableCell>{item.created_at}</TableCell>
										<TableCell>{item.processed_date}</TableCell>
										<TableCell align='right'>
											<Box component={Link} to={`/plan/copy/file/${item.id}`}>
												<IconButton size="small" color="primary">
													<ZoomInOutlinedIcon />
												</IconButton>
											</Box>
										</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Paper>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Paper elevation={2}>
					<SectionTitle 
						label='Processed Lines'
						rightComponent={
							<>
								<Box component={Link} to={`/plan/copy/upload?mode=line`}>
									<Button color="primary" variant="contained" startIcon={<AddBoxOutlinedIcon />}>Add Line</Button>
								</Box>
							</>
						}
					/>
					<Box sx={css.tableSection}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Status</TableCell>
										<TableCell>Created Date</TableCell>
										<TableCell>Processed Date</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{_.isNull(plans) && <TableRow>
									<TableCell colSpan={5} sx={css.loadingSection}>
										<CircularProgress />
									</TableCell>
								</TableRow>}
								{plans?.map(item => (
									<TableRow hover key={`plan_item${item.id}`}>
										<TableCell>
										{item.error && 
											<Chip size="small" color="error" label="Errors" variant="contained"/>
										}
										{!item.error && 
											<Chip size="small" color="success" label="Complete" variant="outlined"/>
										}
										</TableCell>
										<TableCell>{item.created_at}</TableCell>
										<TableCell>{item.copy_date}</TableCell>
										<TableCell align='right'>
											<Box component={Link} to={`/plan/copy/line/${item.id}`}>
												<IconButton size="small" color="primary">
													<ZoomInOutlinedIcon />
												</IconButton>
											</Box>
										</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	</Box>	
}

export default CopyPlan

const css = {
	loadingSection: {
		height: '200px',
		textAlign: 'center',
	}
}