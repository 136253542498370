import {
	useState,
	useEffect,
	useRef,
	useMemo,
} from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
	Button,
	Tooltip,
	CircularProgress,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	IconButton,
	TextField,
	Chip, MenuItem, Menu,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SectionTitle from '../../components/common/SectionTitle';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import ClientService from '../../services/ClientService';
import TipsContext from '../../providers/GlobalTip';
import TagService from '../../services/TagService';
import {Link} from "react-router-dom";
import * as React from "react";

function ClientTags(props) {
	const fileInputRef = useRef();
	const currentClientContext = CurrentClientContext.useClient();
	const tip_context = TipsContext.useMessage();
	const [uploading, setUploading] = useState(false);
	const [tags, setTags] = useState([]);
	const [masterClasses, setMasterClasses] = useState([]);
	const [locationClasses, setLocationClasses] = useState([]);
	const [tagKeyword, setTagKeyword] = useState('');
	const [classKeyword, setClassKeyword] = useState('');
	const [locations, setLocations] = useState([]);
	const [masterClassKeyword, setMasterClassKeyword] = useState('');
	const [locationKeyword, setLocationKeyword] = useState('');
	const [relatedTags, setRelatedTags] = useState();
	let [openSettingMenu, setOpenSettingMenu] = React.useState(false)
	const downloadButtonAnchor = React.useRef(null)

	const chooseFile = () => {
		fileInputRef.current.click()
	}

	useEffect(() => {
		if (currentClientContext.client) {
			getClientClasses();
			getClientLocations();
			loadTags();
			loadRelatedTags();
			loadLocationClasses();
		}
	}, [currentClientContext.client])

	const loadLocationClasses = async () => {
		const result = await ClientService.getClientLocations(currentClientContext.client.Id, {
			include: ['classLinks.class']
		})
		let locationClasses = []
		result.map(item => {
			item.class_links.map(cl => {
				locationClasses.push(cl)
			})
		})
		setLocationClasses(locationClasses)
	}

	const loadTags = async() => {
		const data = await TagService.getTagGroups();
		let final = [];
		data.forEach(g => {
			g.tags.forEach(t => {
				final.push({
					id: t.id,
					name: t.name,
					group_name: g.name,
				});
			});
		});
		setTags(final);
	}

	async function loadRelatedTags() {
		const result = await ClientService.getClientRelatedTags(currentClientContext.client.Id);
		setRelatedTags(result);
	}

	async function getClientClasses() {
		try {
			const categories = await ClientService.getMasterCategories();
			let classes = [];
			categories.forEach(c => {
				c.master_classes.forEach(i => {
					classes.push({
						id: i.id,
						name: i.name,
						category_id: c.id,
						category_name: c.name,
					})
				})
			});
			setMasterClasses(classes)
		} catch(e) {
		}
	}

	async function uploadClientTag(e) {
		try {
			setUploading(true)
			const result = await TagService.uploadClientTag(e.target.files[0])
			tip_context.showSuccess('Upload successfully')
			loadRelatedTags();
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setUploading(false)
	}

	const getClientLocations = async() => {
		const result = await ClientService.getClientLocations(currentClientContext.client.Id, {})
		setLocations(result)
	}

	function toggleSettingMenu() {
		setOpenSettingMenu(!openSettingMenu)
	}

	return (
		<Box sx={{py:2}}>
			<Typography variant="h5">Tags</Typography>
			<Grid container spacing={2} sx={{mt: 0}}>
				<Grid item xs={12} md={4}>
					<Paper elevation={2}>
						<SectionTitle 
							type="primary"
							label="Upload Tags"
							rightComponent={
							<>
								<Tooltip title="Download Template">
									<IconButton ref={downloadButtonAnchor} onClick={toggleSettingMenu} sx={{color: 'white'}}>
										<DownloadRoundedIcon />
									</IconButton>
								</Tooltip>
								<Menu
									open={openSettingMenu}
									anchorEl={downloadButtonAnchor.current}
									onClose={e => {setOpenSettingMenu(false)}}
									transformOrigin={{ horizontal: 'right', vertical: 'top' }}
									anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
								>
									<MenuItem component="a" href="/templates/client_tags_new.csv" dense target="_blank">
										Client Tags Template
									</MenuItem>
									<MenuItem component="a" href="/templates/location_tags.csv" dense target="_blank">
										Location Tags Template
									</MenuItem>
									<MenuItem component="a" href="/templates/master_category_tags.csv" dense target="_blank">
										Master Category Tags Template
									</MenuItem>
									<MenuItem component="a" href="/templates/master_class_tags.csv" dense target="_blank">
										Master Class Tags Template
									</MenuItem>
								</Menu>
							</>
							}
						/>
						<Box sx={{p:1.5}}>
							<Box>Client ID: {currentClientContext.client?.Id}</Box>
							<Box 
								component="input" 
								type='file' 
								name="data" 
								sx={{display:'none'}} 
								ref={fileInputRef}
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
								onChange={uploadClientTag}
								onClick={(event)=> {
									event.target.value = null
								}}
							></Box>
							<LoadingButton
								loading={uploading}
								fullWidth
								size="large"
								startIcon={<CloudUploadRoundedIcon />}
								onClick={chooseFile}
								sx={css.uploadButton}
							>Upload</LoadingButton>
						</Box>
						{relatedTags && (
							<>
								{relatedTags.with_client.length>0 && (
									<>
										<Box sx={css.linkedTitle}>Client Tags</Box>
										<Box sx={css.linkedTags}>
											{relatedTags.with_client.map(i => (
												<Chip label={i.name} size="small" variant="outlined" key={`client_tag_${i.id}`}/>
											))}
										</Box>
									</>
								)}
								{relatedTags.with_location.length>0 && (
									<>
										<Box sx={css.linkedTitle}>Location Tags</Box>
										<Box sx={css.linkedTags}>
											{relatedTags.with_location.map(address => {
												return (
													<Box sx={css.linkedTagItem}>
														<Box>{address?.location_name}</Box>
														<Box>{address.tags.map(t => <Chip label={t.name} size="small" variant="outlined" key={`client_location_${t.id}`} />)}</Box>
													</Box>
												);
											})}
										</Box>
									</>
								)}
								{relatedTags.with_master_category.length>0 && (
									<>
										<Box sx={css.linkedTitle}>Master Category Tags (LocationClasses)</Box>
										<Box sx={css.linkedTags}>
											{relatedTags.with_master_category.map(i => {
												return (
													<Box sx={css.linkedTagItem}>
														<Box>{i.master_category?.name}</Box>
														<Box><Chip label={i.location_class?.locationclass_name} size="small" variant="outlined" key={`client_master_category_${i.location_class?.locationclassid}`} /></Box>
													</Box>
												);
											})}
										</Box>
									</>
								)}
								{relatedTags.with_master_class.length>0 && (
									<>
										<Box sx={css.linkedTitle}>Master Class Tags (LocationClasses)</Box>
										<Box sx={css.linkedTags}>
											{relatedTags.with_master_class.map(i => {
												return (
													<Box sx={css.linkedTagItem}>
														<Box>{i.master_class?.name}</Box>
														<Box><Chip label={i.location_class?.locationclass_name} size="small" variant="outlined" key={`client_master_class_${i.location_class.locationclassid}`} /></Box>
													</Box>
												);
											})}
										</Box>
									</>
								)}
							</>
						)}
						
					</Paper>
				</Grid>
				<Grid item xs={12} md={4}>
					<Paper elevation={2}>
						<SectionTitle
							label="Tags"
							rightComponent={
								<TextField
									label="Search"
									variant="outlined"
									size="small"
									value={tagKeyword}
									onChange={e => setTagKeyword(e.target.value)}
								/>
							}
						/>
						<TableContainer sx={{ maxHeight: 655 }}>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell align='right'>Group</TableCell>
										<TableCell align='right'>Name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{tags.filter(i => {
										if (tagKeyword) {
											return i.group_name.toLowerCase().indexOf(tagKeyword.toLowerCase())>-1 || i.name.toLowerCase().indexOf(tagKeyword.toLowerCase())>-1
										} else {
											return true;
										}
									}).map(i => (
										<TableRow key={`tag_${i.id}`}>
											<TableCell sx={{whiteSpace:'nowrap'}}>{i.id}</TableCell>
											<TableCell align='right'>{i.group_name}</TableCell>
											<TableCell align='right'>{i.name}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Grid>
				<Grid item xs={12} md={4}>
					<Paper elevation={2}>
						<SectionTitle
							label="Master Classes"
							rightComponent={
								<TextField
									label="Search"
									variant="outlined"
									size="small"
									value={masterClassKeyword}
									onChange={e => setMasterClassKeyword(e.target.value)}
								/>
							}
						/>
						<TableContainer sx={{ maxHeight: 290 }}>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell align='right'>Category</TableCell>
										<TableCell align='right'>Class</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{masterClasses.filter(i => {
									if (masterClassKeyword) {
										return i.category_name.toLowerCase().indexOf(masterClassKeyword.toLowerCase())>-1 || i.name.toLowerCase().indexOf(masterClassKeyword.toLowerCase())>-1
									} else {
										return true;
									}
								}).map(i => (
									<TableRow key={i.id}>
										<TableCell sx={{whiteSpace:'nowrap'}}>{i.id}</TableCell>
										<TableCell align='right'>[{i.category_id}] {i.category_name}</TableCell>
										<TableCell align='right'>{i.name}</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
					<Paper elevation={2} sx={{mt: 2}}>
						<SectionTitle
							label="Locations"
							rightComponent={
								<TextField
									label="Search"
									variant="outlined"
									size="small"
									value={locationKeyword}
									onChange={e => setLocationKeyword(e.target.value)}
								/>
							}
						/>
						<TableContainer sx={{ maxHeight: 290 }}>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell align='right'>Code</TableCell>
										<TableCell align='right'>Name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{locations.filter(i => {
									if (locationKeyword) {
										return i.name.toLowerCase().indexOf(locationKeyword.toLowerCase())>-1
									} else {
										return true;
									}
								}).map(i => (
									<TableRow key={i.client_location_id}>
										<TableCell sx={{whiteSpace:'nowrap'}}>{i.client_location_id}</TableCell>
										<TableCell align='right'>{i.location_code}</TableCell>
										<TableCell align='right'>{i.location_name}</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
					<Paper elevation={2} sx={{mt:2}}>
						<SectionTitle
							label="Location Classes"
							rightComponent={
								<TextField
									label="Search"
									variant="outlined"
									size="small"
									value={classKeyword}
									onChange={e => setClassKeyword(e.target.value)}
								/>
							}
						/>
						<TableContainer sx={{ maxHeight: 655 }}>
							<Table size="small" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell>Location ID</TableCell>
										<TableCell align='center'>Location Class ID (for mapping)</TableCell>
										<TableCell align='center'>Location Class Code</TableCell>
										<TableCell align='right'>Name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{locationClasses.filter(i => {
										if (classKeyword) {
											return i.locationclass_name.toLowerCase().indexOf(classKeyword.toLowerCase())>-1 || i.locationclassid.toLowerCase().indexOf(classKeyword.toLowerCase())>-1
										} else {
											return true;
										}
									}).map(i => (
										<TableRow key={`tag_${i.id}`}>
											<TableCell sx={{whiteSpace:'nowrap'}}>{i.client_location_id}</TableCell>
											<TableCell align='center'>{i.locationclassid}</TableCell>
											<TableCell align='center'>{i.clientclass_code + ' (' + i.service_type_code + ')'}</TableCell>
											<TableCell align='right'>{i.locationclass_name}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Grid>
			</Grid>
		</Box>
	);
}

const css = {
	linkedTitle: {
		px: 2,
		py: 1,
		backgroundColor: '#F3f4f5',
		fontSize: 14,
		fontWeight: 'bold',
		mt: 2,
	},
	linkedTags: {
		p: 1,
	},
	linkedTagItem: {
		display: 'flex',
		justifyContent: 'space-between',
		p: 1,
		borderBottom: '1px solid #f3f4f5',
		transition: 'all 0.3s',
		':hover': {
			backgroundColor: '#f3f4f5',
		},
	},
	uploadButton: {
		py: 5,
		border: '1px dashed transparent',
		borderColor: 'primary.main',
		borderRadius: 2,
		mt: 2,
	},
}

export default ClientTags;