import { Box, Button, CardHeader, FormControlLabel, Paper, Stack, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from 'moment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Checkbox } from "@mui/material";

const timezoneOptions = [
	'America/New_York',
	'America/Chicago',
	'America/Denver',
	'America/Los_Angeles',
	'America/Anchorage',
	'Pacific/Honolulu',
];

const JobSettings = ({ jobSettings, onSave, onRun, onDelete, clientCode, toggleJob }) => {
	// const [openMore, setOpenMore] = useState(false);
	// const moreElement = useRef(null);

	// const handleMenuClick = (action) => {
	// 	setOpenMore(false);
	// 	switch(action) {
	// 		case 'run':
	// 			onRun();
	// 			break;
	// 		case 'delete':
	// 			onDelete();
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// }

	const [runDate, setRunDate] = useState(moment());
	const [sinceLastRun, setSinceLastRun] = useState(false);
	const [disabledSinceLastRun, setDisabledSinceLastRun] = useState(false);
	const [loadFullInventory, setLoadFullInventory] = useState(false);
	const [skipInventory, setSkipInventory] = useState(false);

	const [jobData, setJobData] = useState(jobSettings);

	useEffect(() => {
		setJobData(jobSettings);
	}, [jobSettings]);

	useEffect(() => {
		if (!(runDate.month() == moment().month() && runDate.year() == moment().year())) {
			setSinceLastRun(false);
			setDisabledSinceLastRun(true);
		} else {
			setDisabledSinceLastRun(false);
		}
	}, [runDate]);

	return (
		<Paper elevation={2} sx={{mb:2}}>
			<Box sx={{mb:2}} spacing={2}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<CardHeader
						title={'Flight Control Logs for ' + (clientCode ?? '')}
						sx={{
							backgroundColor: 'white',
							'& .MuiTypography-root': {
									fontSize: '16px',
							},
							flexGrow: 1,
							borderBottom: '1px solid #ccc',
						}}
					/>
					{/* {(jobSettings != null && jobSettings != 'undefined' && jobSettings != '') ? (
						<>
							<IconButton onClick={e => { setOpenMore(true) }} ref={moreElement}>
								<MoreVertOutlined sx={{ color: 'black' }} />
							</IconButton>
							<Menu
								open={openMore}
								anchorEl={moreElement.current}
								onClose={e => { setOpenMore(false) }}
							>
								<MenuItem dense onClick={() => handleMenuClick('run')}>Run</MenuItem>
								<MenuItem dense onClick={() => handleMenuClick('delete')}>Delete</MenuItem>
							</Menu>
						</>
					) : (
						<IconButton onClick={onSave}>
							<AddIcon sx={{ color: 'black' }} />
						</IconButton>
					)} */}
				</div>
				{jobSettings ?
					<>
						<Box sx={{ mx: 2, mb: 2 }}>
							<Stack sx={{mb: 2}}>
								<FormControlLabel
									sx={{ ml: 1 }}
									control={
										<Checkbox
											checked={sinceLastRun}
											onChange={(e) => setSinceLastRun(e.target.checked)}
											color="primary"
											disabled={disabledSinceLastRun}
										/>
									}
									label="Data since last run only?"
								/>
								<FormControlLabel
									sx={{ ml: 1 }}
									control={
										<Checkbox
											checked={loadFullInventory}
											onChange={(e) => setLoadFullInventory(e.target.checked)}
											color="primary"
										/>
									}
									label="Load full inventory?"
								/>
								<FormControlLabel
									sx={{ ml: 1 }}
									control={
										<Checkbox
											checked={skipInventory}
											onChange={(e) => setSkipInventory(e.target.checked)}
											color="primary"
										/>
									}
									label="Skip Inventory?"
								/>
							</Stack>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DatePicker
									views={['year', 'month']}
									label="Run Date"
									minDate={moment('2000-01-01')}
									maxDate={moment('2100-01-01')}
									value={runDate}
									onChange={(newValue) => {
										setRunDate(newValue);
										if (newValue.month() !== moment().month()) {
											setSinceLastRun(false);
										}
									}}
									renderInput={(params) => <TextField {...params} />}
									inputFormat="MMM YYYY"
								/>
							</LocalizationProvider>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onRun({period: runDate, sinceLastRun: sinceLastRun, loadFullInventory: loadFullInventory, skipInventory: skipInventory})}>Run</Button>
						</Box>
						<Box
							sx={{ 
								px: 2,
								pt: 2,
								borderTop: '1px solid #ccc',
							}}
						>
							<TextField
								label="Time Zone"
								select
								value={jobData.time_zone}
								onChange={(e) => setJobData({...jobData, 'time_zone': e.target.value})}
								SelectProps={{
									native: true,
								}}
								sx={{ width: 300 }}
							>
								<option value=""></option>
								{timezoneOptions.map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</TextField>
						</Box>
						<Box id="action-buttons" sx={{ m: 2 }}>
							<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => onSave(jobData)}>Save Setings</Button>
							{jobSettings.enabled ?
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(false)}>Disable Daily Run</Button> :
								<Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Enable Daily Run</Button>
							}
						</Box>
					</>
				: <Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => toggleJob(true)}>Create Job Settings</Button>}
			</Box>
		</Paper>
	);
}

export default JobSettings;