import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';

const useJune = (writeKey) => {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    if (!writeKey || writeKey === '' || writeKey === 'undefined') {
      return;
    }
    // log the first 3 letters of the writeKey
    console.log(`useJune: ${writeKey.slice(0, 3)}`);

    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  return analytics;
}

export default useJune;