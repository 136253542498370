import CurrentClientContext from '../../providers/CurrentClientProvider';
import ClientService from '../../services/ClientService';
import React from 'react';

const ClientSync = () => {
    const { client } = CurrentClientContext.useClient();
    const [syncing, setSyncing] = React.useState(false)
    const [synced, setSynced] = React.useState(false)

    const syncClient = async () => {
        setSyncing(true)
        try {
            let result = await ClientService.syncClientByCode(client.Id)

            setSyncing(false)

            if (result == 0) {
                setSynced(true)
            } else {
                alert('Error during sync:', result);
            }
        } catch (error) {
            console.error('Error during sync:', error);
            alert('Error during sync:', error);
            setSyncing(false)
        }
    }

    if (!client) {
        return (
            <div>
            <h1>Client Sync (only run if data is missing)</h1>
            <p>Client loading...</p>
            </div>
        );
    }

    return (
        <div>
        <h1>Client Sync (only run if data is missing)</h1>
        <p>Client: {client.ClientCode}</p>
        {!syncing ? 
            <>
                {synced ? 
                    <p>Already synced</p>
                : <button onClick={syncClient} disabled={syncing}>Sync from Hero DB</button>}
            </>
        : <p>Syncing... this may take several minutes</p>}
        {synced ? <p>Synced!</p> : <p>Not synced yet.</p>}
        </div>
    );
}

export default ClientSync;