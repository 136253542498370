import { useEffect, useState } from "react"
import {Typography, Grid, Paper, Stepper, Step, StepButton, Button,} from "@mui/material"
import GeneralStep from "../../components/client/setup/steps/GeneralStep"
import UploadClassStep from "../../components/client/setup/steps/UploadClassStep"
import ConfirmLocationStep from "../../components/client/setup/steps/ConfirmLocationStep"
import UserStep from "../../components/client/setup/steps/UserStep"
import SubmitProfileStep from "../../components/client/setup/steps/SubmitProfileStep"
import ApprovalStep from "../../components/client/setup/steps/ApprovalStep";
import ClientContext from "../../providers/CurrentClientProvider"
import { useNavigate } from "react-router"

const jss = {
  detailSection: {
    py: 2,
  },
}

// TODO: Rework this to directly display each element depending on the current step so we can use states better and reduce db load
const steps = [
  {
    label: "Create client profile",
    description: "Name the new client and create a unique client code.",
    component: GeneralStep,
  },
  {
    label: "Import classes",
    description: "Import client classes and locations.",
    component: UploadClassStep,
  },
  {
    label: "Confirm locations",
    description:
      "Check and complete key data for each of the client's locations.",
    component: ConfirmLocationStep,
  },
  {
    label: "Add users",
    description:
      "Assign or create client, affiliate and planner accounts for this client.",
    component: UserStep,
  },
  {
    label: "Submit profile",
    description: "Submit the profile to be verified by our team.",
    component: SubmitProfileStep,
  },
  {
    label: "Approval",
    description: "Welcome to Retail Orbit!",
    component: ApprovalStep,
  },
]

function ClientSetup() {
  const [activeStep, setActiveStep] = useState(0)
  const [disableContinue, setDisableContinue] = useState(false)
  const client_context = ClientContext.useClient()
  const navigate = useNavigate()

  function goToNextStep() {
    setActiveStep((prev) => prev + 1)
  }

  function goToStep(step) {
    setActiveStep(step)
  }

  useEffect(() => {
    if (!client_context.client) {
      navigate("/")
    }
  }, [client_context.client])

  const isLastStep = activeStep === steps.length - 1

  const StepComponent = steps[activeStep].component
  const currentPageName = steps[activeStep].label;

  return (
    <Grid container spacing={2} sx={jss.detailSection}>
      <Grid item xs={12} sm={12}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Grid container>
            <Grid item xs={10} sm={10}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(({ label, description }, index) => (
                  <Step key={label}>
                    <StepButton color="inherit" onClick={() => goToStep(index)}>
                      {label}
                      <Typography
                        sx={{
                          "fontSize": "0.75rem",
                          color: activeStep === index ? "#212121" : "#9e9e9e",
                        }}
                      >
                        {description}
                      </Typography>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Button variant="contained" fullWidth onClick={goToNextStep} disabled={isLastStep || disableContinue}>
                Complete Step
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12}>
        <StepComponent setDisableContinue={setDisableContinue} />
      </Grid>
    </Grid>
  )
}

export default ClientSetup
