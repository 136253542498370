import { useState } from 'react'
import GeneralClientCreateForm from '../../components/client/setup/steps/GeneralClientCreateForm'

const ClientAdd = () => {
    return (
        <div>
            <GeneralClientCreateForm setSetupMode='create' />
        </div>
    )
}

export default ClientAdd