import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	IconButton,
	Chip,
	Paper,
	Switch,
} from '@mui/material';
import jss from '../../components/client/profile/ProfileCommonJss'
import CurrentClientContext from '../../providers/CurrentClientProvider'
import ClientProfileLocationList from '../../components/client/profile/ClientProfileLocationList'
import ClientProfileClassList from '../../components/client/profile/ClientProfileClassList'
import ClientLocationClassLinkEdit from '../../components/client/profile/ClientLocationClassLinkEdit'
import ClassBulkEdit from '../../components/client/profile/ClassBulkEdit'
import ClientService from '../../services/ClientService'
import TipsContext from '../../providers/GlobalTip'
import ClientClassEdit from '../../components/client/profile/ClientClassEdit'
import _ from 'underscore'
import ClientClassCreate from "../../components/client/class/ClientClassCreate";
import LabelService from "../../services/LabelService";
import AuthProvider from "../../providers/AuthProvider";

// TODO: Clean up these states. We don't need this many and having so many also causes issues with updating states after posting to update an object.
// Several states are also arrays when they don't need to be
function ClientClasses(props) {

	const [locations, setLocations] = useState()
	const [classes, setClasses] = useState()
	const [location, setLocation] = useState();
	const tip_context = TipsContext.useMessage()
	const currentClientContext = CurrentClientContext.useClient()

	const [currentClasses, setCurrentClasses] = useState([])

	const [currentLocations, setCurrentLocations] = useState([])
	const [currentLocationClasses, setCurrentLocationClasses] = useState([])
	const [multiMode, setMultiMode] = useState(false)
	const [labelData, setLabelData] = useState()
	const [categories, setCategories] = useState()
	const [masterCategories, setMasterCategories] = useState([])
	const [isAdmin, setIsAdmin] = useState(false)
	const auth = AuthProvider.useAuth()

	// is admin
	useEffect(() => {
		// role-create marks admin, but we don't have role specific checks currently
		if (auth.checkPermission('role-create')) {
			setIsAdmin(true)
		} else {
			setIsAdmin(false)
		}
	}, [auth])

	useEffect(() => {
		if( currentClientContext.client ) {
			getClientClasses()
			getClientLocations()
			loadLabels()
			loadCategories()
			loadMasterCategories();
		}
	}, [currentClientContext.client])

	async function loadLabels() {
		const data = await LabelService.getLabels()
		setLabelData(data)
	}

	async function loadCategories() {
		const data = await ClientService.getClientCategories(currentClientContext.client.Id)
		setCategories(data)
	}

	async function loadMasterCategories() {
		const data = await ClientService.getMasterCategories();
		setMasterCategories(data)
	}

	async function getClientClasses() {
		const result = await ClientService.getClientClasses(currentClientContext.client.Id, {
			include: [
				'parentClass',
				'locationLinks',
				'labels',
				'categories',
				'warehouseLocation',
				'childClasses',
				'masterClasses',
			]
		})
		const childs = _.groupBy(result.filter(item => {return !!item.parent_class_id}), 'parent_class_id')
		let data = []
		result.filter(item =>{return !item.parent_class_id}).forEach(item => {
			data.push({...item, _type:'parent'})
			if( childs[item.id] ) {
				childs[item.id].forEach(_child => {
					data.push({..._child, _type:'child'})
				})
			}
		})
		setClasses(data)
	}

	async function getClientLocations() {
		const result = await ClientService.getClientLocations(currentClientContext.client.Id, {
			include: ['classLinks.labels', 'classLinks.class', 'classLinks.masterClasses.masterClass', 'classLinks.masterCategories.masterCategory', 'classLinks.children', 'classLinks.categories', 'classLinks.parentClass', 'pos', 'labels']
		})
		setLocations(result)
	}

	function classChoose(items) {
		setCurrentClasses(items)
		if( !multiMode ) {
			setCurrentLocations([])
		}
	}

	function locationChoose(items) {
		setCurrentLocations(items)
		items[0].class_links.map(_lc => {
			if (_lc.clientclass_id == currentClasses[0].id) {
				setCurrentLocationClasses([_lc]);
			}
		})
	}

	/**
	 * Handle class deletion by updating location class links and client classes
	 *
	 * @param {Object} deletedClass - The class
	 * 
	 * @returns {void}
	 */
	function handleDeleteClass(deletedClass) {
		// TODO: Rework these states to be more efficient

		console.log(deletedClass)

		// for each location, find the location class with the deleted class id and update it
		locations.forEach((location) => {
			location.class_links.map((locationClassLink) => {
				if (locationClassLink.clientclass_id == deletedClass.id) {
					return {
						...locationClassLink,
						isActive: deletedClass.isActive,
						deleted_at: deletedClass.deleted_at,
					};
				}
				return locationClassLink;
			});
		});

		// update the client class
		setCurrentClasses(currentClasses.map((clientClass) => {
			if (clientClass.id == deletedClass.id) {
				return {
					...deletedClass,
				};
			}
			return clientClass;
		}));
	}

	function handleSingleClassUpdate(_l) {
		let _classes = currentClasses.map(i => {
			if( i.id == _l.id ) {
				return _l
			} else {
				return i
			}
		})

		let _allClasses = classes.map(_cl => _cl)
		_allClasses = _allClasses.map(_c => {
			if( _c.id == _l.id ) {
				return _l
			} else {
				return _c
			}
		})
		setCurrentClasses(_classes)
		setClasses(_allClasses)
		getClientLocations()
	}

	function onBulkDeleted(deletedClasses) {
		setClasses(classes.filter(item => {
			return deletedClasses.map(i => {return i.id}).indexOf(item.id)>-1
		}))
		getClientLocations()
		getClientClasses
	}
	function handleDeleteLocations(deletedLocation) {
		setLocation(deletedLocation);
	}

	/**
	 * Handles update to client location class by prepetuating it onto react states
	 */
	const handleClientLocationClassChange = (updatedClientLocationClass) => {
		// update the corresponding client location class in the current location classes
		const updatedLocationClasses = currentLocationClasses.map((locationClass) => {
			if (locationClass.locationclassid == updatedClientLocationClass.locationclassid) {
				return updatedClientLocationClass;
			}
			return locationClass;
		});
		setCurrentLocationClasses(updatedLocationClasses);

		// update location_links on current class
		const updatedClasses = currentClasses.map((clientClass) => {
			if (clientClass.id == updatedClientLocationClass.clientclass_id) {
				const updatedLocationLinks = clientClass.location_links.map((locationLink) => {
					if (locationLink.locationclassid == updatedClientLocationClass.locationclassid) {
						return updatedClientLocationClass;
					}
					return locationLink;
				});
				return {
					...clientClass,
					location_links: updatedLocationLinks,
				};
			}
			return clientClass;
		});
		setCurrentClasses(updatedClasses);

		// update current location class links
		const updatedLocation = currentLocations[0];
		const updatedLocationClassLinks = updatedLocation.class_links.map((locationClassLink) => {
			if (locationClassLink.locationclassid == updatedClientLocationClass.locationclassid) {
				return updatedClientLocationClass;
			}
			return locationClassLink;
		});
		const updatedLocationWithUpdatedClassLinks = {
			...updatedLocation,
			class_links: updatedLocationClassLinks,
		};
		setCurrentLocations([updatedLocationWithUpdatedClassLinks]);
	}

	function createClass(item, removeTmp = false) {
		let _c = classes.map(_cls => _cls)
		if (removeTmp) {
			_c = _c.filter(_cls => {
				if (_cls.id === -1 && removeTmp === _cls.name) {
					return false;
				}
				return true;
			})
		}

		item.client_id = currentClientContext.client.Id
		_c.unshift(item)
		setClasses(_c)
		setCurrentClasses([item])

		getClientLocations()
	}
	const updateLocations = (updatedLocations) => {
		setLocations(updatedLocations);
	  };

	return <Box sx={{py:2}}>
		{/* parent component */}
		<Grid container spacing={2}>
			<Grid item xs={12} sm={7}>
				<Box sx={jss.title}>CLASSES</Box>
				<Box sx={jss.dataGrid}>
					{/* child component (class table) */}
					<ClientProfileClassList
						classes={classes}
						onClassChange={classChoose}
						onMultiModeChange={e => setMultiMode(e.target.checked)}
						createClass={createClass}
						multiMode={multiMode}
						isAdmin={isAdmin}
					/>
					{/* child component (location component table) */}
					<ClientProfileLocationList
						locations={locations}
						currentClasses={currentClasses}
						onLocationChange={locationChoose}
						onCurrentClassChange={handleSingleClassUpdate}
						multiMode={multiMode}
						assignAvailable={true}
						updateLocations={updateLocations}
						isAdmin={isAdmin}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} sm={5}>
				<Box sx={jss.title}> </Box>
				{
					!multiMode && !!currentClasses.length && currentClasses[0].id === -1 &&
					<>
					{/* child component (create class create) */}
						<ClientClassCreate classItem={currentClasses[0]}
										   allClasses={classes}
										   locations={locations}
										   onSave={createClass}
										   masterCategories={masterCategories}
										   labelData={labelData}
										   categories={categories}
										   tip_context={tip_context}
						/>
					</>
				}
				{!multiMode && !!currentClasses.length && currentClasses[0].id !== -1 &&
					<>
					    {/* client edit */}
						<ClientClassEdit
							clientClass={currentClasses[0]}
							locations={locations}
							onSave={handleSingleClassUpdate}
							allClasses={classes}
							masterCategories={masterCategories}
							labelData={labelData}
							categories={categories}
							isAdmin={isAdmin}
							onDeleteChange={handleDeleteClass}
							tip_context={tip_context}
						/>
						{/* {currentClasses.length>0 &&
							<ClientLocationClassLinkEdit location={currentLocations[0]} clientClass={currentClasses[0]}/>
						} */}
					</>
				}
				{isAdmin && multiMode && currentClasses.length>0 &&
					<>
						<ClassBulkEdit classes={currentClasses} locations={locations} onDeleted={onBulkDeleted}/>
					</>
				}
				{(!multiMode && !!currentLocations.length && !!currentLocationClasses.length && currentLocations.drop_date !== null) &&
					<>
						<ClientLocationClassLinkEdit location={currentLocations[0]}
													 clientClass={currentLocationClasses[0]}
													 categories={categories}
													 labels={labelData}
													 locationClasses={currentLocations[0].class_links}
													 onSave={handleClientLocationClassChange}
													 isAdmin={isAdmin}
						/>
					</>
				}
			</Grid>
		</Grid>
	</Box>
}

export default ClientClasses