import {useState, useEffect} from 'react'
import {
  useNavigate
} from "react-router-dom";
import {
	Container,
	TextField,
	Grid,
	Typography,
	Paper,
	Link
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import UserService from '../services/UserService'
import AuthContext from '../providers/AuthProvider'
import useJune from '../providers/JuneProvider'
import ClientContext from '../providers/CurrentClientProvider'
import ClientService from '../services/ClientService';

function NotLogin(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('') 
	const [errors, setErrors] = useState({})
	const [loging, setLoging] = useState(false)
	const navigate = useNavigate()
	const auth = AuthContext.useAuth()
	const june = useJune(process.env.REACT_APP_JUNE_WRITE_KEY);
	const selectedClient = ClientContext.useClient()

	useEffect(() => {
		resetError()
	}, [])

	function handleEmail(e) {
		setEmail(e.target.value)
		resetError()
	}

	function handlePassword(e) {
		setPassword(e.target.value)
		resetError()
	}

	function resetError() {
		setErrors({
			email: false,
			email_message: '',
			password: false,
			password_message: '',
		})
	}

	async function login() {
		try{
			if( !email.match(/.+?@.+?/) ) {
				setErrors({
					...errors,
					email: true,
					email_message: 'Please enter the correct email'
				})
				return
			}
			if( !password ) {
				setErrors({
					...errors,
					password: true,
					password_message: 'Please enter password'
				})
				return
			}
			setLoging(true)
			const _user = await UserService.login(email, password, true)
			auth.login(_user)

			let client
			if (!_user.client) {
				client = await UserService.getSingleClient()
			} else {
				client = _user.client
			}

			// if user roles array contains object with "name" Admin, set isAdmin to true + other roles
			let isAdmin = false
			let isAffiliate = false
			let isClient = false
			let isUser = false

			_user.roles.forEach(role => {
				if(!isAdmin && role.name === 'Admin') {
					isAdmin = true
				}
				if(!isAffiliate && role.name === 'Affiliate') {
					isAffiliate = true
				}
				if(client && !isClient && role.name === 'Client') {
					isClient = true
				}
				if(!isUser && role.type === 'USER') {
					isUser = true
				}
			})

			// June reporting
			try {
				// identify in june for tracking
				june.identify(_user.id, {
					email: _user.email,
					name: _user.first_name + ' ' + _user.last_name,
					isAdmin: isAdmin,
					isAffiliate: isAffiliate,
					isClient: isClient,
					isUser: isUser,
				});

				// if user isClient, group them
				if(isClient && !isAffiliate && !isAdmin) {
					june.track('Signed In', {
						userId: _user.id,
						event: 'Signed In',
						properties: {
							browser: 'chrome'
						},
					}, {
						context: {
							groupId: client.ClientCode
						}
					});

				} else if (isAffiliate || isAdmin) {
					june.track('Signed In', {
						userId: _user.id,
						event: 'Signed In',
						properties: {
							browser: 'chrome'
						}
					});
				}
			} catch (e) {
				console.log("Something went wrong with June");
				console.log(e)
			}

			// if client is not null then they only have one client so auto select it
			if (_user.client) {
				selectedClient.setClient(_user.client)
			}

			setLoging(false)
			navigate('/users')
		} catch(e) {
			console.log(e)
			if (e.response?.data) {
				setErrors({
					...errors,
					password: true,
					password_message: e.response.data.message
				})
			}
			setLoging(false)
		}
	}

	return <>
		<Container fixed>
			<Grid container sx={{pt: 20}}>
				<Grid item xs={0} sm={2} lg={3}></Grid>
				<Grid item xs={12} sm={8} lg={6}>
					<Paper sx={{p: 3, pb:4}}>
						<Typography variant="h5">
							Retail ORBIT®
						</Typography>
						<Typography variant="subtitle1" sx={{mb:3}}>
							Login to your account
						</Typography>
						<TextField 
							fullWidth
							label="Email Address"
							value={email}
							name="email"
							onChange={handleEmail}
							error={errors.email}
							helperText={errors.email_message}
							sx={{backgroundColor: 'white'}}
						/>
						<TextField 
							fullWidth
							label="Password"
							type='password'
							value={password}
							name="password"
							onChange={handlePassword}
							error={errors.password}
							helperText={errors.password_message}
							sx={{mt: 2, backgroundColor: 'white'}}
						/>
						<LoadingButton
							variant="contained"
							loading={loging}
							color='primary'
							sx={{mt: 2, mb: 2}}
							fullWidth
							size="large"
							onClick={login}
							startIcon={<ExitToAppRoundedIcon />}
						>
							Sign In
						</LoadingButton>
						<Link
							href="forgot-password"
							underline="hover"
							sx={{ textTransform: 'uppercase'}}
						>
							forgot password?
						</Link>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	</>
}

export default NotLogin