import React, {useState, useRef, useEffect, useMemo} from 'react';
import {
    TextField,
    Grid,
    Button,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, 
    Chip, 
    Paper,
    Backdrop,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    FormControlLabel,
    Switch,
    Autocomplete,
} from '@mui/material';
import {useParams, useNavigate, useSearchParams, Navigate} from "react-router-dom";
import _ from 'underscore'
import ClientService from '../../services/ClientService';
import PlanService from '../../services/PlanService';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import ModifyStyle from '../../components/plan/PlanStyle'
import BasePlanEdit from '../../components/plan/BasePlanEdit'
import FuturePlanEdit from '../../components/plan/FuturePlanEdit'
import TipContext from '../../providers/GlobalTip'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

const BasePlanModify = (props) => {

    const currentClientContext = CurrentClientContext.useClient()
    const baseInputProps = {
        renderInput: (params) => (
          <TextField {...params} variant="standard" sx={ModifyStyle.selectStyle} />
        ),
        renderOption: (props, option) => (
            <Box {...props}>{props.key}</Box>
        ),
        disableClearable: true,
        blurOnSelect: true,
        sx: {width: 200},
    }
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState(props?.type ?? 'base')
    const {
        location_class_id,
    } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const preventAutoRedirect = parseInt(searchParams.get('preventAutoRedirect') ? parseInt(searchParams.get('preventAutoRedirect')) : 0)
    const [clientBasicData, setClientBasicData] = useState()
    const [locationLinkGroup, setLocationLinkGroup] = useState({})
    const [optionValue, setOptionValue] = useState({
        location_id: '',
        class_id: '',
    })
    const [locationClassNotFound, setLocationClassNotFound] = useState(false)
    const navigate = useNavigate()
    const messageContext = TipContext.useMessage()
    const [onlyPlannable, setOnlyPlannable] = useState(false)

    useEffect(() => {
        if( currentClientContext.client ) {
            loadClientBasicData()
        }
    }, [currentClientContext.client, location_class_id])

    useEffect(() => {
        if( clientBasicData?.live_plan && !preventAutoRedirect ) {
            toLivePlan(clientBasicData.live_plan)
        } else {
            if( clientBasicData?.current_location_class ) {
                setOptionValue({
                    location_id: clientBasicData.current_location_class.client_location_id,
                    class_id: clientBasicData.current_location_class.clientclass_id
                })
            }
        }
    }, [clientBasicData])

    useEffect(() => {
        if( optionValue.location_id && optionValue.class_id ) {
            setLocationClassNotFound(false)
            let _link = getLocationClassIdByLocationAndClass(optionValue.location_id, optionValue.class_id)
            if( _link ) {
                navigate(`/clients/${currentClientContext.client.ClientCode}/plan/locationclasses/${_link.locationclassid}?preventAutoRedirect=${preventAutoRedirect}`)
            } else {
                setLocationClassNotFound(true)
            }
        }
    }, [optionValue])

    useEffect(() => {
        if( clientBasicData?.all_links ) {
            let _data = []
            if( !onlyPlannable ) {
                _data = clientBasicData.all_links
            } else {
                _data = clientBasicData.all_links.filter(item => {
                    return PlanService.isPlannable(item)
                })
            }
            setLocationLinkGroup(_.groupBy(_data, 'client_location_id'))
        }
    }, [clientBasicData, onlyPlannable])

    const loadClientBasicData = async() => {
        if( !clientBasicData || location_class_id=='default' ) {
            try {
                setLoading(true)
                const basicInfo = await PlanService.getLocationClassBaseInfo(currentClientContext.client.Id, location_class_id)
                setClientBasicData(basicInfo)
            } catch(e) {
                messageContext.show(e.response?.data?.message)
            }
            setLoading(false)    
        } else {
            setClientBasicData({
                ...clientBasicData,
                current_location_class: clientBasicData.all_links.find(item => {
                    return item.locationclassid == location_class_id
                })
            })
        }
    }

    const getLocationClassIdByLocationAndClass = (location_id, class_id) => {
        const result = _.find(clientBasicData.all_links, item => {
            return item.client_location_id == location_id && item.clientclass_id == class_id
        })
        return result
    }

    const toLivePlan = (live_plan) => {
        navigate(`/clients/${currentClientContext.client.ClientCode}/live-plan/${live_plan.plan_id}/${live_plan.plan_version}`)
    }

    const plannableSwitch = (e) => {
        setOnlyPlannable(e.target.checked)
    }

    const currentLocationOwnedClasses = useMemo(() => {
        return locationLinkGroup.hasOwnProperty(optionValue?.location_id) ? _.pluck(locationLinkGroup[optionValue.location_id], 'clientclass_id') : []
    }, [locationLinkGroup, optionValue])

    return <Box sx={ModifyStyle.mainContainer}>
         <Box sx={ModifyStyle.filterHeader}>
            <Box style={{...ModifyStyle.alignCenter,...ModifyStyle.textFields}}>
                {clientBasicData?.current_location_class?.service_type_code && <>
                    <Box sx={ModifyStyle.textStyle}>{clientBasicData?.current_location_class?.service_type_code}</Box>
                    <Box sx={ModifyStyle.textFieldSplit}></Box>    
                </>}
                <Autocomplete 
                    value={optionValue.location_id}
                    options={_.pluck(clientBasicData?.locations, 'client_location_id').filter(id => locationLinkGroup.hasOwnProperty(id))}
                    getOptionLabel={id => {
                        const _locations = _.indexBy(clientBasicData?.locations, 'client_location_id')
                        if( _.isEmpty(_locations) ) {
                            return ''
                        }
                        return `[${_locations[id]?.location_code}] ${_locations[id]?.location_name}`
                    }}
                    onChange={(e, newValue) => setOptionValue({...optionValue, location_id:newValue})}
                    {...baseInputProps}
                />
                <Box sx={ModifyStyle.textFieldSplit}></Box>
                <Autocomplete 
                    value={optionValue.class_id}
                    options={_.pluck(clientBasicData?.classes, 'id').filter(id => currentLocationOwnedClasses.indexOf(parseInt(id))>-1)}
                    getOptionLabel={id => {
                        const _classes = _.indexBy(clientBasicData?.classes, 'id')
                        if( _.isEmpty(_classes) ) {
                            return ''
                        }
                        return `[${_classes[id]?.code}] ${_classes[id]?.name}`
                    }}
                    onChange={(e, newValue) => setOptionValue({...optionValue, class_id:newValue})}
                    {...baseInputProps}
                />
            </Box>
            <Box 
                sx={{
                    flex: 1,
                    px: 2,
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <FormControlLabel 
                    control={ <Switch size="small" checked={onlyPlannable} onChange={plannableSwitch} /> }
                    label="Plannable"
                    sx={{
                        flexDirection: 'column-reverse',
                        ml:0.5,
                        '.MuiFormControlLabel-label': {
                            fontSize: 12,
                        }
                    }}
                />
                <ToggleButtonGroup
                  color="primary"
                  value={mode}
                  exclusive
                  size="small"
                  onChange={e => setMode(e.target.value)}
                >
                    <ToggleButton value="base">Base</ToggleButton>
                    <ToggleButton value="live" disabled={!clientBasicData?.live_plan}>Live</ToggleButton>
                    <ToggleButton value="future">Open/Close</ToggleButton>
                </ToggleButtonGroup>
            </Box>
         </Box>
         <Box sx={ModifyStyle.mainSection}>
            {loading && <Box sx={ModifyStyle.loadingSection}>
                <CircularProgress color="primary" size={50} />
            </Box>}
            {!loading && locationClassNotFound && <Box sx={ModifyStyle.loadingSection}>
                <GppMaybeOutlinedIcon sx={{ fontSize: 50 }} />
                This Location-Class not found
            </Box>}
            {!loading && mode=='base' && <BasePlanEdit 
                clientBasicData={clientBasicData}
                client={currentClientContext.client}
                optionValue={optionValue}
                onOptionValueChange={e => setOptionValue(e)}
            />}
            {!loading && mode=='future' && <FuturePlanEdit 
                clientBasicData={clientBasicData}
                client={currentClientContext.client}
                optionValue={optionValue}
                onOptionValueChange={e => setOptionValue(e)}
            />}
            {!loading && mode=='live' && clientBasicData?.live_plan && (
                <Navigate to={`/clients/${currentClientContext.client.ClientCode}/live-plan/${clientBasicData.live_plan.plan_id}/${clientBasicData.live_plan.plan_version}`} />
            )}
         </Box>
    </Box>
}

export default BasePlanModify;
