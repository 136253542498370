import {
	useState,
	useEffect
} from "react";
import {
	Box,
	Grid,
} from '@mui/material';
import jss from '../../components/client/profile/ProfileCommonJss'
import CurrentClientContext from "../../providers/CurrentClientProvider";
import ClientService from "../../services/ClientService";
import ClientCategoryClassList from "../../components/client/category/ClientCategoryClassList";
import ClientCategoryList from "../../components/client/category/ClientCategoryList";
import ClientCategoryEdit from "../../components/client/category/ClientCategoryEdit";
import AuthProvider from "../../providers/AuthProvider";
import {sortBy} from "underscore";

function ClientCategories(props) {
	const [categories, setCategories] = useState()
	const [classes, setClasses] = useState()
	const [allClasses, setAllClasses] = useState([])
	const [currentCategory, setCurrentCategory] = useState(null)
	// const [locations, setLocations] = useState()
	const currentClientContext = CurrentClientContext.useClient()
	const [isAdmin, setIsAdmin] = useState(true)
	// const auth = AuthProvider.useAuth()

	// is admin
	// SPS-3105
	// useEffect(() => {
	// 	// role-create marks admin, but we don't have role specific checks currently
	// 	if (auth.checkPermission('role-create')) {
	// 		setIsAdmin(true)
	// 	} else {
	// 		setIsAdmin(false)
	// 	}
	// }, [auth])

	useEffect(() => {
		if( currentClientContext.client ) {
			getClientCategories()
			getClientClasses()
		}
	}, [currentClientContext.client])

	async function getClientClasses() {
		const data = await ClientService.getClientClasses(currentClientContext.client.Id);
		setAllClasses(data)
	}

	async function getClientCategories(setCurrent = true) {
		const result = await ClientService.getClientCategories(currentClientContext.client.Id, ['classes'])
		let _c = []
		let _categories = []
		if (result[0]) {
			result[0].classes.map(item => {
				_c.push(item)
			})

			_categories = result.sort((a, b) => {
				return parseInt(a.SortOrder) - parseInt(b.SortOrder)
			})

			if (setCurrent) {
				setCurrentCategory(result[0])
			}
		}
		setCategories(_categories)
		setClasses(_c)
	}

	function categoryChange(items) {
		setClasses(items.classes)
		setCurrentCategory(items)
	}

	function classChoose(chosenClass, type) {
		if (type === 'add') {
			let _c = classes;
			_c.push(chosenClass)
			_c.sort((a, b) => {
				if (a.code < b.code) {
					return -1;
				}

				if (a.code > b.code) {
					return 1;
				}

				return 0;
			})
			setClasses(_c)
		} else {
			let _classes = classes.filter((item) => {
				return item.id != chosenClass.id
			})
			setClasses(_classes)
		}
	}

	function deleteCategory(categoryId) {
		let _categories = categories.filter((item) => {
			return item.Id != categoryId
		})
		setCategories(_categories)
	}

	function updateCategory(item) {
		let _categories = categories.map(i => {
			if (i.Id == item.Id) {
				return item;
			} else {
				return i;
			}
		}).sort((a, b) => a.SortOrder - b.SortOrder)

		setCategories(_categories)

	}

	function createCategory(item) {
		let _c = categories.map(_cat => _cat);
		_c.push(item)

		if (!categories) {
			setCurrentCategory(item)
		}

		setCategories(_c)
	}

	return <Box sx={{py:2}}>
		<Grid container spacing={2}>
			<Grid item xs={12} sm={7}>
				<Box sx={jss.title}>CATEGORIES</Box>
				<Box sx={jss.dataGrid}>
					<ClientCategoryList
						categories={categories}
						onCategoryChange={categoryChange}
						createCategory={createCategory}
						currentCategory={currentCategory}
						clientId={currentClientContext?.client?.Id}
						isAdmin={isAdmin}
					/>
					<ClientCategoryClassList
						classes={classes}
						onClassChange={classChoose}
						allClasses={allClasses}
						currentCategory={currentCategory}
						updateCategories={getClientCategories}
						isAdmin={isAdmin}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} sm={5}>
				<Box sx={jss.title}> </Box>
					{ currentCategory &&
						<ClientCategoryEdit category={currentCategory} updateCategory={updateCategory} deletedCategory={deleteCategory} isAdmin={isAdmin} />
					}
			</Grid>
		</Grid>
	</Box>
}

export default ClientCategories