import React from 'react';
import Button from '@mui/material/Button';
import DevToolService from '../../services/DevToolService';
import TipContext from '../../providers/GlobalTip';

const devToolsAvailable = [
    {
        name: 'Dev Tools Card',
        onRun: () => {
            let res = DevToolService.lightspeedPopulateMissingDataFilesForLastMonth();
        },
    },
];


const DevTools = () => {
    const [jobStatuses, setJobStatuses] = React.useState([]);
    const [jobsRunning, setJobsRunning] = React.useState([]);
    const tip_context = TipContext.useMessage()

    /**
     * Run lightspeed populate missing data files for last month.
     * @returns {void}
     */
    const runLightspeedPopulateMissingDataFilesForLastMonth = async () => {
        setJobsRunning([...jobsRunning, 'lightspeed-last-month-data-files']);
    
        let key = 'lightspeed-last-month-data-files';
    
        try {
            let res = await DevToolService.lightspeedPopulateMissingDataFilesForLastMonth();
    
            // Update the state with the new key-value pair
            setJobStatuses({
                ...jobStatuses,
                [key]: {'status': "Success", 'message': res.message}
            });
        } catch (error) {
            tip_context.show('Error running lightspeed populate missing data files for last month');
    
            // Update the state with the new key-value pair
            setJobStatuses({
                ...jobStatuses,
                [key]: {'status': "Error", 'message': error.message}
            });
        }
    
        setJobsRunning(jobsRunning.filter(job => job !== 'lightspeed-last-month-data-files'));
    }

    return (
        <div>
            <h1>Dev Tools</h1>
            <div style={{ border: '1px solid #000', padding: '10px', margin: '10px' }}>
                <h2>Populate Missing Data Files for Last Month Lightspeed Clients</h2>
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={runLightspeedPopulateMissingDataFilesForLastMonth}
                    disabled={jobsRunning.includes('lightspeed-last-month-data-files')}
                >
                    Run Tool
                </Button>
                <div>
                    {jobStatuses['lightspeed-last-month-data-files'] ?
                        <div>
                            <h3>Status: {jobStatuses['lightspeed-last-month-data-files'].status}</h3>
                            <p>{jobStatuses['lightspeed-last-month-data-files'].message}</p>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
};


export default DevTools;