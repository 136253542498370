import React, {useState, useRef, useEffect, useMemo, useContext} from 'react';
import {
    TextField,
    Grid,
    Button,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, 
    Chip, 
    Paper,
    Backdrop,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    Autocomplete,
    IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {useParams, useNavigate, Link} from "react-router-dom";
import _ from 'underscore'
import ClientService from '../../services/ClientService';
import PlanService from '../../services/PlanService';
import CurrentClientContext from '../../providers/CurrentClientProvider';
import ModifyStyle from '../../components/plan/PlanStyle'
import LivePlanEdit from '../../components/plan/LivePlanEdit'
import TipContext from '../../providers/GlobalTip'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import moment from 'moment';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

const LivePlanModify = () => {

    const currentClientContext = CurrentClientContext.useClient()
    const baseInputProps = {
        variant: "standard",
        sx: ModifyStyle.selectStyle
    }
    const baseSelectProps = {
        renderInput: (params) => (
          <TextField {...params} variant="standard" sx={ModifyStyle.selectStyle} />
        ),
        renderOption: (props, option) => (
            <Box {...props}>{props.key}</Box>
        ),
        disableClearable: true,
        blurOnSelect: true,
        sx: {width: 200},
    }
    const [loading, setLoading] = useState(true);
    const {
        plan_id,
        plan_version
    } = useParams();
    const [clientBasicData, setClientBasicData] = useState()
    const [locationLinkGroup, setLocationLinkGroup] = useState({})
    const [optionValue, setOptionValue] = useState({
        location_id: '',
        class_id: '',
    })
    const [planData, setPlanData] = useState()
    const [locationClassNotFound, setLocationClassNotFound] = useState(false)
    const navigate = useNavigate()
    const messageContext = TipContext.useMessage()
    const [createFuturePlanTrigger, setCreateFuturePlanTrigger] = useState(false)
    const [mode, setMode] = useState('live')
    const [futureCreated, setFutureCreated] = useState(true)
    const [removingPlan, setRemovingPlan] = useState(false)
    const isHeader = JSON.parse(localStorage.getItem('showHeaderContainer'))
    const [showHeaderContainer, setShowHeaderContainer] = useState( isHeader === null ? true : isHeader);

    useEffect(() => {
        localStorage.setItem('showHeaderContainer', JSON.stringify(showHeaderContainer));
    }, [showHeaderContainer]);

    useEffect(async () => {
        const getLatestPlanVersion = async () => {
            return await PlanService.getLatestPlanVersionForPlan(currentClientContext.client.Id, plan_id)
        }

        if (currentClientContext.client && plan_version && plan_version == 'default') {
            try {
                setLoading(true)
                let result = await getLatestPlanVersion()

                if (result['plan_id'] && result['max_version']) {
                    await navigate(`/clients/${currentClientContext.client.ClientCode}/live-plan/${result['plan_id']}/${result['max_version']}`)
                } else {
                    messageContext.show('Something went wrong loading max version of plan')
                }
            } catch (e) {
                if (e.response?.data?.message) {
                    messageContext.show(e.response.data.message)
                }
            }
            setLoading(false)
        } else if (currentClientContext.client) {
            await loadClientBasicData()
        }
    }, [currentClientContext.client, plan_id, plan_version])

    useEffect(() => {
        if( clientBasicData ) {
            setOptionValue({
                location_id: clientBasicData.current_plan.client_location_id,
                class_id: clientBasicData.current_plan.client_class_id,
            })
        }
    }, [clientBasicData])

    const createFuturePlan = () => {
        setFutureCreated(false)
        setCreateFuturePlanTrigger(true)
        setTimeout(() => {
            setCreateFuturePlanTrigger(false)
        }, 1000)
    }

    const findLocationClass = (location_id, class_id) => {
        setLocationClassNotFound(false)
        let _link = getLocationClassIdByLocationAndClass(location_id, class_id)

        if( _link ) {
            navigateToPlanVersion(_link.plan_settings_id, 'default')
        } else {
            setLocationClassNotFound(true)
        }
    }

    const loadClientBasicData = async() => {
        try {
            setLoading(true)
            const basicInfo = await PlanService.getLivePlanBaseInfo(currentClientContext.client.Id, plan_id, plan_version)
            setClientBasicData(basicInfo)
            setLocationLinkGroup(_.groupBy(basicInfo.all_live_plans.filter(item => {
                return isPlannable(item)
            }), item => {
                return item.client_location_id
            }))
        } catch(e) {
            console.log(e)
            messageContext.show(e)
        }
        setLoading(false)    
    }

    const getLocationClassIdByLocationAndClass = (location_id, class_id) => {
        const result = _.find(clientBasicData.all_live_plans, item => {
            return item.client_location_id == location_id && item.client_class_id == class_id
        })
        return result
    }

    const removeFuturePlan = async (plan) => {
        setRemovingPlan(true)
        try {
            const last_plan = await PlanService.removePlan(currentClientContext.client.Id, plan.plan_id, plan.plan_version)
            setRemovingPlan(false)
            navigateToPlanVersion(planData?.versions[1].plan_id, planData?.versions[1].version)
        } catch(e) {
            setRemovingPlan(false)
            messageContext.show(e.response?.data.message)
        }
    }

    const navigateToPlanVersion = (plan_id, plan_version) => {
        navigate(`/clients/${currentClientContext.client.ClientCode}/live-plan/${plan_id}/${plan_version}`)
    }

    const makePlanLive = async() => {
        try {
            await PlanService.makePlanLive(currentClientContext.client.Id, clientBasicData.current_plan.plan_id, clientBasicData.current_plan.plan_version)
            loadClientBasicData()
        } catch(e) {
            messageContext.show(e)
        }
    }

    const isPlannable = (locationClass) => {
        return locationClass.service_type_id==1 || locationClass.service_type_id==2
    }

    const currentLocationOwnedClasses = useMemo(() => {
        return locationLinkGroup.hasOwnProperty(optionValue?.location_id) ? _.map(locationLinkGroup[optionValue.location_id], item => (item.client_class_id)) : []
    }, [optionValue, locationLinkGroup])

    const onFutureCreated = () => {
        setFutureCreated(true)
    }


    return <Box sx={ModifyStyle.mainContainer}>
         <Box sx={ModifyStyle.filterHeader}>
            <Box style={{...ModifyStyle.alignCenter,...ModifyStyle.textFields}}>
                {clientBasicData?.current_plan?.plan_id && <>
                    <Box sx={ModifyStyle.textStyle}>
                        {planData?.versions && planData.versions.length<=1 && <>
                            {clientBasicData?.current_plan?.plan_id} - {clientBasicData?.current_plan?.plan_version} - {clientBasicData?.current_plan?.service_type_code}
                        </>}
                        {planData?.versions && planData.versions.length>1 && <>
                            {clientBasicData?.current_plan?.plan_id} - 
                            <Box 
                                component="select" 
                                sx={{
                                    mx: 0.5,
                                    height: '30px',
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    borderColor: 'rgba(255,255,255,0.6)',
                                    'option': {
                                        backgroundColor: '#5c4174!important',
                                    }
                                }}
                                onChange={e => navigateToPlanVersion(e.target.options[e.target.selectedIndex].dataset.id, e.target.value)}
                                defaultValue={clientBasicData?.current_plan?.plan_version}
                                value={clientBasicData?.current_plan?.plan_version}
                            >
                                {planData.versions.map(item => {
                                    return <option key={`version_item_${item.version}`} value={item.version} data-id={item.plan_id}>{item.version} {item.display}</option>
                                })}
                            </Box>
                            {/*{planData.versions.filter(i => i.version!='1000.00').find(item => { return clientBasicData.current_plan.plan_version == item.version;})?.display?.indexOf('*')==-1 && <Button */}
                            {/*    size="small" */}
                            {/*    onClick={makePlanLive}*/}
                            {/*    sx={ModifyStyle.makeLiveButton}*/}
                            {/*>Make Live</Button>}*/}
                             - {clientBasicData?.current_plan?.service_type_code}
                        </>}
                    </Box>
                    <Box sx={ModifyStyle.textFieldSplit}></Box>    
                </>}

                <Autocomplete 
                    value={String(optionValue.location_id)}
                    options={_.pluck(clientBasicData?.locations, 'client_location_id').filter(id => locationLinkGroup.hasOwnProperty(id))}
                    getOptionLabel={id => {
                        const _locations = _.indexBy(clientBasicData?.locations, 'client_location_id')
                        if( _.isEmpty(_locations) ) {
                            return ''
                        }
                        return `[${_locations[id]?.location_code}] ${_locations[id]?.location_name}`
                    }}
                    onChange={(e, newValue) => {
                        setOptionValue({...optionValue, location_id:newValue})
                        findLocationClass(newValue, optionValue.class_id)
                    }}
                    {...baseSelectProps}
                />
                <Box sx={ModifyStyle.textFieldSplit}></Box>
                <Autocomplete 
                    value={String(optionValue.class_id)}
                    options={_.pluck(clientBasicData?.classes, 'id').filter(id => currentLocationOwnedClasses.indexOf(parseInt(id))>-1)}
                    getOptionLabel={id => {
                        const _classes = _.indexBy(clientBasicData?.classes, 'id')
                        if( _.isEmpty(_classes) ) {
                            return ''
                        }
                        return `[${_classes[id]?.code}] ${_classes[id]?.name}`
                    }}
                    onChange={(e, newValue) => {
                        setOptionValue({...optionValue, class_id:newValue})
                        findLocationClass(optionValue.location_id, newValue)
                    }}
                    {...baseSelectProps}
                />

                {!clientBasicData?.current_plan?.is_future_plan && planData?.allplans && <>
                    <Box sx={ModifyStyle.textFieldSplit}></Box>
                    <TextField
                        sx={{
                            ...ModifyStyle.selectStyle,
                            'option': {
                                backgroundColor: '#5c4174 !important'
                            }
                        }}
                        name="otherMonths"
                        select
                        SelectProps={{native: true}}
                        onChange={e => {
                            const _plan = planData.allplans.find(i => {
                                return i.plan_id == e.target.value
                            })
                            if( _plan ) {
                                navigate(`/clients/${currentClientContext.client.ClientCode}/live-plan/${_plan.plan_id}/${_plan.plan_version}`)    
                            }
                        }}
                        defaultValue={clientBasicData?.current_plan?.plan_id}
                        value={clientBasicData?.current_plan?.plan_id}
                    >
                        {planData.allplans.sort((a, b) => {
                            return b.plan_id*1 - a.plan_id*1
                        }).sort((a, b) => {
                            return new Date(b.year, b.month, 1) - new Date(a.year, a.month, 1)
                        }).filter((value, index, self) => {
                            return self.findIndex(v => value.month === v.month && value.year === v.year && v.plan_version*1 < 1000) === index;
                        }).map(item =>
                            <option 
                                key={`other_month_item_${item.plan_id}`} 
                                value={item.plan_id}
                            >
                                {moment(`${item.year}-${item.month}-01`).format('MMM YYYY')}
                            </option>
                        )}
                    </TextField>
                </>}
            </Box>
            <Box 
                sx={{
                    flex: 1,
                    px: 2,
                    alignItems: 'center',
                    display: 'flex',
                }}
            >

                <ToggleButtonGroup
                    color={showHeaderContainer ? 'secondary' : 'primary'}
                    value={showHeaderContainer}
                    size="small"
                    sx={{
                        mr: 2
                    }}
                    >
                    <ToggleButton value={true} onClick={e => setShowHeaderContainer(!showHeaderContainer)}>Show Header</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                  color="primary"
                  value={mode}
                  exclusive
                  size="small"
                >
                    <ToggleButton disabled={!clientBasicData?.current_plan} value="base" onClick={() => navigate(`/clients/${currentClientContext.client?.ClientCode}/plan/locationclasses/${clientBasicData.current_plan?.LocationClassId}?preventAutoRedirect=1`)}>Base</ToggleButton>
                    <ToggleButton value="live">Live</ToggleButton>
                    <ToggleButton disabled={!planData?.is_opening_closing} value="future" onClick={() => navigate(`/clients/${currentClientContext.client.ClientCode}/plan-future-base/locationclasses/${planData.locationclassid}?preventAutoRedirect=1`)}>Open/Close</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box sx={ModifyStyle.alignCenter}>
                {!!clientBasicData?.current_plan?.is_future_plan && <>
                    <LoadingButton
                        variant="outlined" 
                        startIcon={<DeleteOutlinedIcon />}
                        loading={removingPlan}
                        sx={{
                            ml: 2, 
                            mt: {xs: 2, sm: 0},
                        }}
                        onClick={e => removeFuturePlan(clientBasicData?.current_plan)}
                    >Remove Future Plan</LoadingButton>
                </>}
                {!clientBasicData?.current_plan?.is_future_plan && <>
                    {planData?.has_future_plan && <Button 
                        variant="outlined" 
                        endIcon={<NavigateNextOutlinedIcon />} 
                        sx={{
                            ml: 2, 
                            mt: {xs: 2, sm: 0},
                            borderColor: '#FF0800'
                        }}
                        onClick={e => navigate(`/clients/${currentClientContext.client.ClientCode}/live-plan/${planData?.future_plan_id}/${planData?.future_plan_version}`)}
                    >Edit Future Plan</Button>}
                    {!_.isEmpty(planData) && !planData?.has_future_plan && (
                        <LoadingButton 
                            variant="outlined"
                            loading={!futureCreated}
                            startIcon={<AddIcon />} 
                            sx={{
                                ml: 2, 
                                mt: {xs: 2, sm: 0},
                            }}
                            onClick={e => {
                                createFuturePlan()
                            }}
                        >
                            Create Future Plan
                        </LoadingButton>
                    )}
                </>}
            </Box>
         </Box>
         <Box sx={ModifyStyle.mainSection}>
            {loading && <Box sx={ModifyStyle.loadingSection}>
                <CircularProgress color="primary" size={50} />
            </Box>}
            {!loading && locationClassNotFound && <Box sx={ModifyStyle.loadingSection}>
                <GppMaybeOutlinedIcon sx={{ fontSize: 50 }} />
                This Location-Class not found
            </Box>}
            {!loading && !locationClassNotFound && <LivePlanEdit
                clientBasicData={clientBasicData}
                client={currentClientContext.client}
                optionValue={optionValue}
                onOptionValueChange={e => {
                    setOptionValue(e)
                    findLocationClass(e.location_id, e.class_id)
                }}
                onPlanDataLoad={e => {
                    setPlanData(e)
                    onFutureCreated()
                }}
                createFuturePlan={createFuturePlan}
                removeFuturePlan={removeFuturePlan}
                createFuturePlanTrigger={createFuturePlanTrigger}
                onFutureCreated={e => onFutureCreated()}
                showHeaderContainer={showHeaderContainer}
                setShowHeaderContainer={setShowHeaderContainer}
            />}
         </Box>
    </Box>
}

export default LivePlanModify;
