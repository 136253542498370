import {
	useState,
	useEffect,
	useRef
} from "react";
import {
	Navigate,
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Box,
	Typography,
	Container,
	Grid,
	Paper,
	Skeleton,
	IconButton,
	TextField,
	Checkbox,
	Button,
	Tooltip,
	CircularProgress,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
	FormControlLabel,
	Divider,
	Switch, MenuItem, Menu, Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import SectionTitle from '../../components/common/SectionTitle'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import CurrentClientContext from '../../providers/CurrentClientProvider'
import ClientService from '../../services/ClientService'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import moment from 'moment'
import TipsContext from '../../providers/GlobalTip'
import EmptyIcon from '../../components/icons/EmptyIcon'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DataFileInfo from '../../components/client/uploadData/DataFileInfo'
import DataFileService from '../../services/DataFileService'
import constant from '../../constants/constant'
import SettingsIcon from '@mui/icons-material/Settings';
import SettingModal from '../../components/client/uploadData/SettingModal'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import TableauReport from "../../components/tableau/TableauReport";
import AuthProvider from "../../providers/AuthProvider";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";


const jss = {
	changeLogItem: {
		display:'flex',
		p:1,
		border:'1px solid #ccc',
		borderRadius:1,
		mt:1,
		transition: 'all 0.3s',
		info: {
			flex:1,
			wordBreak:'break-all',
			cursor:'pointer',
		},
		name: {
			whiteSpace:"wrap",
			fontSize:13,
		},
		extra: {
			fontSize:11,
			mt:0.5,
		},
		checkIcon: {
			ml:1,
			cursor:'pointer',
		},
		checkIconHead: {
			ml:1,
			cursor:'pointer',
			color: 'white',
			fontSize: '20px'
		},
		":hover": {
			backgroundColor: '#f3f4f5',
		},
		'&.checked': {
			backgroundColor: '#dbcfe6',
			color:'primary.main',
			borderColor: 'primary.main'
		},
	},
	emptySelectLog: {
		textAlign:'center', 
		py:15, 
		color:'text.disabled', 
		fontSize:30
	},
	changeLogSection: {
		title: {
			fontSize:16,
			fontWeight:'bold',
			my:2
		},
		actionSection: {
			p:2,
			backgroundColor: '#eee9f3',
			display:'flex',
			alignItems:'center',
		},
	},
}

function UploadData(props) {
	const auth = AuthProvider.useAuth();
	const fileInputRef = useRef()
	const [dataFiles, setDataFiles] = useState([])
	const [loading, setLoading] = useState(true)
	const [uploading, setUploading] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [loadingSelect, setLoadingSelect] = useState(false)
	const currentClientContext = CurrentClientContext.useClient()
	const [targetDataFile, setTargetDataFile] = useState()
	const [changeLogSummary, setChangeLogSummary] = useState([])
	const tip_context = TipsContext.useMessage()
	const [uploadDataForm, setUploadDataForm] = useState({
		isEndOfMonth: false,
		dataThroughDate: moment().subtract(1, 'day'),
	})
	const [settingOpen, setSettingOpen] = useState(false)
	const [togglingEndOfMonth, setTogglingEndOfMonth] = useState(false)
	const [isFinalizing, setIsFinalizing] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const more_element = useRef()
	const [open_more, setOpenMore] = useState(false)

	useEffect(() => {
		if( currentClientContext.client ) {
			loadDataFiles()
		}
	}, [currentClientContext.client])

	useEffect(() => {
		if( auth.checkPermission('role-create') ) {
			setIsAdmin(true)
		}
	}, [auth.user])

	async function loadDataFiles() {
		const _files = await DataFileService.getClientDataFiles(currentClientContext.client.Id)
		setLoading(false)
		setDataFiles(_files)
	}

	async function selectDataFile(dataFile) {
		setLoadingSelect(true)
		setTargetDataFile(dataFile)
		setLoadingSelect(false)
	}

	function chooseFile() {
		fileInputRef.current.click()
	}

	/**
	 * Handles on change for is end of month checkbox on single files
	 *
	 * @param {Event} event
	 *
	 * @return {void}
	 */
	async function changeDataFileEndOfMonth(event) {
		setTogglingEndOfMonth(true)
		try {
			let result = await DataFileService.updateDataFile(currentClientContext.client.Id, targetDataFile.ClientDataFileId, {
				IsMonthToDate: event.target.checked ? 0 : 1 // inverted because of wording on the checkbox
			})
			dataFileChangeHandler(result)
			tip_context.showSuccess("Data file updated")
		} catch(e) {
			console.log(e)
			tip_context.show("Something went wrong marking the data file as end of month")
		}
		setTogglingEndOfMonth(false)
	}

	/**
	 * Finalize/Unfinalize target file
	 *
	 * @params {boolean} finalize
	 *
	 * @return {void}
	 */
	async function setFinalizeFile(finalize) {
		if (!targetDataFile) {
			return;
		}
		if (finalize && targetDataFile.IsMonthToDate) {
			tip_context.show("Only end of month files can be finalized")
			return
		}
		if (isFinalizing) {
			return
		}
		setIsFinalizing(true)
		try {
			let result;
			if( finalize ) {
				result = await DataFileService.finalizeDataFile(currentClientContext.client.Id, targetDataFile.ClientDataFileId)
			} else {
				result = await DataFileService.unfinalizeDataFile(currentClientContext.client.Id, targetDataFile.ClientDataFileId)
			}

			tip_context.showSuccess("Data file updated")

			console.log(result)

			dataFileChangeHandler(result)
		} catch(e) {
			tip_context.show("Something went wrong finalizing the data file")
		}
		setIsFinalizing(false)
	}

	async function uploadDataFile(e) {
		if( e.target.files.length==0 ) {
			return
		}
		try {
			setUploading(true)
			let data = {
				is_month_to_date: uploadDataForm.isEndOfMonth ? 0 : 1,
			}
			if( !uploadDataForm.isEndOfMonth ) {
				data['data_through_date'] = uploadDataForm.dataThroughDate.format('YYYY-MM-DD 00:00:00')
			}
			const new_data_file = await DataFileService.uploadDataFile(currentClientContext.client.Id, e.target.files[0], data)
			dataFileChangeHandler()
		} catch(e) {
			tip_context.show(e.response?.data.message)
		}
		setUploading(false)
	}

	function dataFileChangeHandler(_updatedDataFile) {
		loadDataFiles()
		if( _updatedDataFile ) {
			setTargetDataFile(_updatedDataFile)	
		}
	}

	function dataFileDeleteHandler(_deletedDataFile) {
		loadDataFiles()
		setTargetDataFile(null)
	}

	function openSettingModal() {
		setSettingOpen(true)
	}

	function closeSettingModal() {
		setSettingOpen(false)
	}

	function backup() {
		DataFileService.clientDataFileBackup(currentClientContext.client.Id)
	}

	async function syncCloudData() {
		try {
			await DataFileService.syncCloudData(currentClientContext.client.Id)
		} catch(e) {
		}
		tip_context.showSuccess()
	}
	// const currentEpoch = ClientService.getEpochMonthFromDate(new Date());

	const downloadDataTemplate = async () => {
		try {
			await DataFileService.downloadDataTemplate(currentClientContext.client.Id, currentClientContext.client.ClientCode)
		} catch (e) {
			// console.log(e)
		}
	}

	const downloadFile = async () => {
		if (!targetDataFile) return false;
		setOpenMore(false)
		try {
			let response = await DataFileService.downloadDataSource(currentClientContext.client.Id, targetDataFile.Period, targetDataFile.FileName)
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement("a");
			link.href = url;

			link.setAttribute("download", targetDataFile.FileName);
			document.body.appendChild(link);
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		} catch (e) {
			tip_context.show("Something went wrong downloading file")
		}
	}

	const deleteFile = async () => {
		if (!targetDataFile) return false;
		if (!confirm(`Are You Sure to Delete File ${targetDataFile.Name}`)) return false;
		setOpenMore(false)
		setLoading(true)

		try {
			let response = await DataFileService.deleteDataFile(currentClientContext.client.Id, targetDataFile.ClientDataFileId)
			setTargetDataFile(null)
			await loadDataFiles()
		} catch (e) {
			if (e.response?.data?.message) {
				tip_context.show(e.response.data.message)
			} else {
				tip_context.show("Something went wrong deleting file")
			}
		}

	}

	return <Box sx={{py:2}}>
		<Typography variant="h5">Import Data</Typography>
		<Grid container spacing={2} sx={{mt:0}}>
			<Grid item xs={12} sm={3}>
				<Paper elevation={2}>
					<SectionTitle 
						label="Import Data"
						rightComponent={
							<>
								<Tooltip title="Sync cloud data">
									<IconButton onClick={syncCloudData}>
										<CloudSyncIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Download Data Template">
									<IconButton onClick={downloadDataTemplate}>
										<DownloadRoundedIcon />
									</IconButton>
								</Tooltip>
							</>
						}
					/>
					<Box >
						<Box sx={{p:1.5}}>
							<FormControlLabel control={<Switch value={uploadDataForm.isEndOfMonth} onChange={e => setUploadDataForm({...uploadDataForm, isEndOfMonth: e.target.checked})} />} label="Is end of month?" />
							{!uploadDataForm.isEndOfMonth &&
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Data through date"
										value={uploadDataForm.dataThroughDate}
										inputFormat="MMM dd"
										mask=""
										onChange={e => setUploadDataForm({...uploadDataForm, dataThroughDate: moment(e)})}
										renderInput={(params) => <TextField {...params}
											fullWidth={true}
											variant="outlined"
											size="small"
											sx={{mt:2}} 
										/>}
									/>
								</LocalizationProvider>
							}
							<Box 
								component="input" 
								type='file' 
								name="data" 
								sx={{display:'none'}} 
								ref={fileInputRef}
								onChange={uploadDataFile}
								onClick={(event)=> {
									event.target.value = null
								}}
							></Box>
							<LoadingButton loading={uploading} variant="contained" fullWidth startIcon={<CloudUploadRoundedIcon />} onClick={chooseFile} sx={{mt:2}}>Upload File</LoadingButton>
						</Box>
						<Divider sx={{mt:1}}/>
						<Box sx={{p:1.5}}>
							<Box sx={{
								display:'flex', 
								justifyContent:'space-between',
								alignItems: 'center',
							}}>
								<Typography variant="body1">Data Files</Typography>
								<Box>
									<Tooltip title="Backup">
										<IconButton onClick={backup}>
											<CloudDownloadOutlinedIcon />
										</IconButton>
									</Tooltip>
									{isAdmin ? 
										<>
										<Tooltip title="Adjust inventory">
											<IconButton onClick={openSettingModal}>
												<SettingsIcon />
											</IconButton>
										</Tooltip>
										<SettingModal 
											client={currentClientContext.client}
											open={settingOpen}
											onClose={closeSettingModal}
											onRefreshDataFiles={dataFileChangeHandler}
										/>
										</>
									: null}
								</Box>
							</Box>
							<Box sx={{maxHeight:'80vh',overflowY:'scroll'}}>
							{dataFiles?.length>0 && dataFiles.map(_file => {
								return <Box key={`data_file_${_file.ClientDataFileId}`} sx={jss.changeLogItem} className={targetDataFile?.ClientDataFileId==_file.ClientDataFileId ? 'checked' : ''} >
									<Box sx={jss.changeLogItem.info} onClick={e => selectDataFile(_file)}>
										<Box sx={jss.changeLogItem.name}>
											<b>{moment(_file.Period, 'YYYYMM').format('MMM YYYY')}</b>
											<Box component="span" sx={{ml:1}}>{_file.Name}</Box>
										</Box>
										<Box sx={jss.changeLogItem.extra}>
											{_file.IsMonthToDate==1 && <>
												{moment(_file.DataThroughDate).format('M/DD')}
											</>}
											{_file.IsMonthToDate==0 && <>
												End of Month
											</>}
											{_file.ProcessedDate &&
												<Box component="span" sx={{ml:1}}>Updated: {moment(_file.ProcessedDate).format('M/DD HH:mm')}</Box>
											}
										</Box>
									</Box>
                                    {!!_file.is_month_to_date && 
                                    	<>MTD</>
                                    }
									{!_file.is_month_to_date && <>
										{!!_file.FinalizedDate && <>
											{!!_file.is_planned && <>
												<LockIcon sx={jss.changeLogItem.checkIcon}/>
											</>}
											{!_file.is_planned && <>
												<LockOutlinedIcon sx={jss.changeLogItem.checkIcon}/>
											</>}
										</>}
										{!_file.FinalizedDate && <>
											<LockOpenIcon sx={jss.changeLogItem.checkIcon}/>
										</>}
									</>}
								</Box>
							})}
							{loading && <Box sx={{textAlign:'center',pt:2}}>
								<CircularProgress color="primary" />
							</Box>
							}
							{dataFiles?.length===0 && !loading && 
								<Box sx={{py:2,textAlign:'center',color:'text.disabled'}}><EmptyIcon sx={{fontSize:60}}/></Box>
							}
							</Box>
						</Box>
					</Box>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={9}>
				<Paper elevation={2}>
					{(!targetDataFile && !loadingSelect) && 
						<Box sx={jss.emptySelectLog}>
							Please select a data file
						</Box>
					}
					{loadingSelect && 
						<Box sx={jss.emptySelectLog}>
							<CircularProgress color="primary" />
						</Box>
					}
					{targetDataFile && currentClientContext && currentClientContext.client && (
						<>
							<Paper>
								<SectionTitle label={'Data File'}
											  subLabel={'[' + moment(targetDataFile.Period, 'YYYYMM').format('MMM YYYY') + ']'}
											  type="primary"
											  rightComponent={
													<Box sx={{display: 'flex', justifyContent: 'center'}}>
														<Box sx={{marginY: 'auto', height: 'fit-content', display: 'flex', justifyContent: 'center'}}>
															<FormControlLabel sx={{ height: 'fit-content', margin: 0, padding: 0, color: 'white' }} control={<Checkbox checked={targetDataFile.IsMonthToDate == 0} sx={{ margin: 0, padding: 0, paddingRight: '5px' }} onChange={changeDataFileEndOfMonth} disabled={togglingEndOfMonth || targetDataFile.FinalizedDate} />} label="Is end of month?" />
															{!targetDataFile.FinalizedDate ?
																<LockOpenIcon sx={jss.changeLogItem.checkIconHead} fill='white' onClick={() => {setFinalizeFile(true)}} />
																: <LockIcon sx={jss.changeLogItem.checkIconHead} onClick={() => {setFinalizeFile(false)}}/>}
														</Box>
														<IconButton onClick={e => {setOpenMore(true)}} ref={more_element} sx={{marginLeft: '20px'}}>
															<MoreVertOutlinedIcon sx={{color: 'white'}} />
														</IconButton>
														<Menu
															open={open_more}
															anchorEl={more_element.current}
															onClose={e => {setOpenMore(false)}}
														>
															<MenuItem dense onClick={downloadFile}>Download Original File</MenuItem>
															{targetDataFile.file_path && targetDataFile.file_path.indexOf('client_dat_imports') === -1 && targetDataFile.file_path.indexOf('client_sku_imports') === -1 ?
																<MenuItem dense onClick={deleteFile}>Delete File</MenuItem> : ''
															}
														</Menu>
													</Box>
											  }
								/>
								<TableauReport epochMonth={ClientService.getEpochMonthFromDate(moment(targetDataFile.Period + '01').toDate())} clientId={currentClientContext.client.Id} url={'https://data.retailorbit.com/trusted/{user_auth_key}/views/clientDataView_QA/Multi-Location?:showAppBanner=false&:display_count=n&:toolbar=top&:alerts=n&:subscriptions=n&:dataDetails=n&:showShareOptions=y&:showVizHome=n&:origin=viz_share_link'} height="100vh" />
							</Paper>
						</>
					)}
				</Paper>
			</Grid>
		</Grid>
	</Box>
}
export default UploadData