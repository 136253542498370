import React, { useState, useEffect } from 'react';
import GlobalTip from '../../providers/GlobalTip';
import CustomCalendarService from '../../services/CustomCalendarService';
import SectionTitle from '../../components/common/SectionTitle';
import BasicTable from '../../components/tables/BasicTable';
import CustomCalendarModal from '../../components/modals/CustomCalendarEditModal';
import { Box, Button } from '@mui/material';
import ClientService from '../../services/ClientService';

const initialCalendar = {
    title: '',
    description: '',
    month1_weeks: 4,
    month2_weeks: 4,
    month3_weeks: 5,
    month4_weeks: 4,
    month5_weeks: 4,
    month6_weeks: 5,
    month7_weeks: 4,
    month8_weeks: 4,
    month9_weeks: 5,
    month10_weeks: 4,
    month11_weeks: 4,
    month12_weeks: 5,
};

const columnOrder = [
    'title',
    'description',
    {
        'month1_weeks': 'Jan',
        'month2_weeks': 'Feb',
        'month3_weeks': 'Mar',
        'month4_weeks': 'Apr',
        'month5_weeks': 'May',
        'month6_weeks': 'Jun',
        'month7_weeks': 'Jul',
        'month8_weeks': 'Aug',
        'month9_weeks': 'Sep',
        'month10_weeks': 'Oct',
        'month11_weeks': 'Nov',
        'month12_weeks': 'Dec',
    }
]

const CustomCalendarManagement = () => {
    const TipContext = GlobalTip.useMessage();
    const [calendars, setCalendars] = useState([]);
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [clientList, setClientList] = useState([]);
    
    useEffect(() => {
        CustomCalendarService.getCalendars()
            .then((response) => {
                setCalendars(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error)
                TipContext.show('Failed to load custom calendars');
            });
        
        const fetchClients = async () => {
            try {
                const response = await ClientService.listClientsSimple();
                setClientList(response);
            } catch (error) {
                console.log(error);
                TipContext.show('Failed to load clients');
            }
        }

        fetchClients();
    }, []);

    const handleCalendarUpdate = (calendar) => {
        if (!calendar) {
            return;
        }
        // push if not exists, update if exists
        const newCalendars = calendars.map((c) => {
            if (c.id === calendar.id) {
                return calendar;
            }
            return c;
        });
        if (!newCalendars.find((c) => c.id === calendar.id)) {
            newCalendars.push(calendar);
        }
        setCalendars(newCalendars);
    }

    const handleDelete = async (calendar) => {
        if (!calendar) {
            return;
        }
        const newCalendars = calendars.filter((c) => c.id !== calendar.id);
        setCalendars(newCalendars);
    }

    const handleRowClick = (row) => {
        setSelectedCalendar(row);
        console.log(row)
        setModalMode('edit');
        setIsEditModalOpen(true);
    }

    return (
        <Box sx={{ minWidth: '800px' }}>
            <SectionTitle label="Custom Calendars" type="primary" customStyles={{ mt: '20px' }} />
            <Box sx={{ mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => {setSelectedCalendar(null); setModalMode('create'); setIsEditModalOpen(true);}}>
                    Add Custom Calendar
                </Button>
            </Box>
            <BasicTable
                data={calendars}
                headers={['Name', 'Description', 'Months']}
                columnOrder={columnOrder}
                onRowClick={handleRowClick}
            />

            <CustomCalendarModal
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSubmit={handleCalendarUpdate}
                onDelete={handleDelete}
                mode={modalMode}
                initialData={selectedCalendar || initialCalendar}
                tipContext={TipContext}
                activeClientList={clientList}
            />
        </Box>
    );
};

export default CustomCalendarManagement;