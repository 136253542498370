import {
	useState,
	useEffect,
} from "react";
import {
	Box,
	Typography,
	Paper,
	IconButton,
	TextField,
	Button,
	Divider,
	Modal,
} from '@mui/material';
import { LoadingButton } from '@mui/lab'
import DataFileService from '../../../services/DataFileService'
import GlobalTip from '../../../providers/GlobalTip'
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const jss = {
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		p: 2,
	}
}

function SettingModal(props) {
	const {client} = props
	const [periodStart, setPeriodStart] = useState(null);
	const [months, setMonths] = useState('');
	const [unfinalizeStart, setUnfinalizeStart] = useState('');
	const [unfinalizeEnd, setUnfinalizeEnd] = useState('');
	const [finalizeStart, setFinalizeStart] = useState('');
	const [finalizeEnd, setFinalizeEnd] = useState('');
	const [finalizationChanging, setFinalizationChanging] = useState(false)
	const [errors, setErrors] = useState({
		periodStart: false,
		months: false,
		unfinalizeStart: false,
		unfinalizeEnd: false,
		finalizeStart: false,
		finalizeEnd: false,
	})
	const [backdating, setBackdating] = useState(false)
	const [perpetuating, setPerpetuating] = useState(false)
	const tipContext = GlobalTip.useMessage()

	async function batchUnfinalize() {
		if( !unfinalizeStart ) {
			setErrors({...errors, unfinalizeStart:true})
			return
		}
		if( !unfinalizeEnd ) {
			setErrors({...errors, unfinalizeEnd:true})
			return
		}
		if (moment(unfinalizeStart).isAfter(unfinalizeEnd)) {
			tipContext.show('Unfinalize Period Start must be less than Unfinalize Period End')
			return
		}
		try {
			setFinalizationChanging(true)

			// convert range to yymm
			let strUnfinalizeStart = moment(unfinalizeStart).format('YYMM')
			let strUnfinalizeEnd = moment(unfinalizeEnd).format('YYMM')

			await DataFileService.batchUnfinalize(client.Id, strUnfinalizeStart, strUnfinalizeEnd)
			props.onClose()
			props.onRefreshDataFiles()
			tipContext.showSuccess()
		} catch(e) {
		}
		setFinalizationChanging(false)
	}

	/**
	 * Batch finalize files submit
	 *
	 * @returns void
	 */
	async function batchFinalize() {
		if ( finalizationChanging ) {
			tipContext.show('Please wait for the current process to finish')
			return
		}
		if ( !finalizeStart ) {
			setErrors({...errors, finalizeStart:true})
			return
		}
		if ( !finalizeEnd ) {
			setErrors({...errors, finalizeEnd:true})
			return
		}
		if (moment(finalizeStart).isAfter(finalizeEnd)) {
			tipContext.show('Finalize Period Start must be less than Finalize Period End')
			return
		}
		setFinalizationChanging(true)
		try {
			// convert range to yymm
			let strFinalizeStart = moment(finalizeStart).format('YYMM')
			let strFinalizeEnd = moment(finalizeEnd).format('YYMM')

			await DataFileService.batchFinalize(client.Id, strFinalizeStart, strFinalizeEnd)
			props.onClose()
			props.onRefreshDataFiles()
			tipContext.showSuccess()
		} catch(e) {
			tipContext.show(e.message)
		}
		setFinalizationChanging(false)
	}

	async function backdate() {
		tipContext.showSuccess('Starting backdate. An email will be sent on completion or failure.')
		try {
			// setBackdating(true)

			// convert periodStart to a string yymm
			let strPeriodStart = moment(periodStart).format('YYMM')
			await DataFileService.backdate(client.Id, strPeriodStart, months)
			props.onClose()
			props.onRefreshDataFiles()
		} catch(e) {
			// clear current tipContext messages
			tipContext.clear()

			tipContext.show(e.response?.data?.message, 5)
		}
		// setBackdating(false)
	}

	async function perpetuate() {
		tipContext.showSuccess('Starting perpetuate. An email will be sent on completion or failure.')
		try {
			// convert periodStart to a string yymm
			let strPeriodStart = moment(periodStart).format('YYMM')

			await DataFileService.perpetuate(client.Id, strPeriodStart, months)
			props.onClose()
			props.onRefreshDataFiles()
		} catch(e) {
			// clear current tipContext messages
			tipContext.clear()

			tipContext.show(e.response?.data?.message, 5)
		}
	}

	return (
		<Modal
			{...props}
		>
			<Paper sx={jss.modal}>
				<Typography variant="h6">Adjust inventory</Typography>
				<Typography variant="body2">Backdate and Perpetuate inventory. Only one job can run at a time.</Typography>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label="Period Start"
						views={['year', 'month']}
						inputFormat="MMM yyyy"
						format="yyMM"
						value={periodStart}
						onChange={(date) => setPeriodStart(date)}
						renderInput={(params) =>
							<TextField {...params}
								fullWidth
								variant="standard"
								size="small"
								sx={{ mt: 2 }}
							/>
						}
					/>
				</LocalizationProvider>
				<TextField
					label="Months"
					value={months}
					error={errors.months}
					onChange={e => {
						setMonths(e.target.value)
						setErrors({ ...errors, months: false })
					}}
					size="small"
					fullWidth
					type="number"
					sx={{ mt: 2 }}
					inputProps={{
						maxLength: 2
					}}
				/>
				<Box sx={{ mt: 2, display: 'flex' }}>
					<LoadingButton loading={backdating} sx={{ mr: 1, }} variant="contained" fullWidth onClick={backdate}>Backdate</LoadingButton>
					<LoadingButton loading={perpetuating} sx={{ ml: 1, }} variant="contained" fullWidth onClick={perpetuate}>Perpetuate</LoadingButton>
				</Box>
				<Divider sx={{ my: 2 }} />
				<Typography variant="h6">Bulk Finalize/Unfinalize</Typography>
				<Typography variant="body2">Unfinalize and Finalize for range of months. Start must be less than end date.</Typography>
				<Divider sx={{ my: 1 }} />
				<Box sx={{ display: 'flex' }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							label="Unfinalize Period Start"
							views={['year', 'month']}
							inputFormat="MMM yyyy"
							format="yyMM"
							value={unfinalizeStart}
							onChange={(date) => {
								setUnfinalizeStart(date)
								setErrors({ ...errors, unfinalizeStart: false })
							}}
							renderInput={(params) =>
								<TextField {...params}
									fullWidth
									variant="standard"
									size="small"
									sx={{ mr: 1 }}
								/>
							}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							label="Unfinalize Period End"
							views={['year', 'month']}
							inputFormat="MMM yyyy"
							format="yyMM"
							value={unfinalizeEnd}
							onChange={(date) => {
								setUnfinalizeEnd(date)
								setErrors({ ...errors, unfinalizeEnd: false })
							}}
							renderInput={(params) =>
								<TextField {...params}
									fullWidth
									variant="standard"
									size="small"
									sx={{ mr: 1 }}
								/>
							}
						/>
					</LocalizationProvider>
				</Box>
				<LoadingButton
					loading={finalizationChanging}
					onClick={batchUnfinalize}
					sx={{ mt: 2, }}
					variant="contained"
					fullWidth
				>
					Unfinalize
				</LoadingButton>
				<Divider sx={{ my: 2 }} />
				<Box sx={{ display: 'flex' }}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							label="Finalize Period Start"
							views={['year', 'month']}
							inputFormat="MMM yyyy"
							format="yyMM"
							value={finalizeStart}
							onChange={(date) => {
								setFinalizeStart(date)
								setErrors({ ...errors, finalizeStart: false })
							}}
							renderInput={(params) =>
								<TextField {...params}
									fullWidth
									variant="standard"
									size="small"
									sx={{ mr: 1 }}
								/>
							}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							label="Finalize Period End"
							views={['year', 'month']}
							inputFormat="MMM yyyy"
							format="yyMM"
							value={finalizeEnd}
							onChange={(date) => {
								setFinalizeEnd(date)
								setErrors({ ...errors, finalizeEnd: false })
							}}
							renderInput={(params) =>
								<TextField {...params}
									fullWidth
									variant="standard"
									size="small"
									sx={{ mr: 1 }}
								/>
							}
						/>
					</LocalizationProvider>
				</Box>
				<LoadingButton
					loading={finalizationChanging}
					onClick={batchFinalize}
					sx={{ mt: 2, }}
					variant="contained"
					fullWidth
				>
					Finalize
				</LoadingButton>
			</Paper>
		</Modal>
	);
}
export default SettingModal