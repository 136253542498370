import {
    useState,
    useEffect,
    useRef
} from "react";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import {useParams} from "react-router-dom";
import ClientService from "../../services/ClientService";
import TableauService from "../../services/TableauService";
import {Grid} from "@mui/material";
import TableauReport from "../../components/tableau/TableauReport";

function ClientPlanPreview(props) {
    let { client_code, clientId } = useParams()
    const [url, setUrl] = useState('https://data.retailorbit.com/trusted/{user_auth_key}/views/AnnualPreviewandMonthlyPreview/AnnualPlanReport?:showAppBanner=false&:toolbar=top&:display_count=n&:showVizHome=n&:origin=viz_share_link')

    return <Grid container>
                <Grid item sx={{marginTop: '15px'}} lg={12} >
                    <TableauReport  clientId={clientId} url={url} height="100vh" />
                </Grid>
            </Grid>
}

export default ClientPlanPreview