import React, {
    useState,
    useEffect,
    useRef
} from "react";

import {
    Paper,
    Box,
    Tooltip,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import SectionTitle from "../../common/SectionTitle";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import CurrentClientContext from "../../../providers/CurrentClientProvider";
import TagService from "../../../services/TagService";
import TipsContext from '../../../providers/GlobalTip';
import ClientService from "../../../services/ClientService";
import PosService from "../../../services/PosService";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

function ClientPosMappingUpload(props) {
    const [uploading, setUploading] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const currentClientContext = CurrentClientContext.useClient();
    const fileInputRef = useRef();
    const tip_context = TipsContext.useMessage();
    const { success, isAdmin } = props
    const [openMore, setOpenMore] = useState(false)
    const more_element = useRef()

    const chooseFile = () => {
        fileInputRef.current.click()
    }

    async function uploadClientPos(e) {
        try {
            setUploading(true)
            const result = await ClientService.uploadClientPos(currentClientContext.client?.Id, e.target.files[0])
            tip_context.showSuccess('Upload successfully')
            success()
        } catch(e) {
            tip_context.show(e.response?.data.message)
        }
        setUploading(false)
    }

    async function downloadPosExample(advancedDownload = false) {
        setDownloading(true)
        setOpenMore(false)
        if (currentClientContext.client) {
            try {
                await PosService.downloadPos({clientId: currentClientContext.client.Id, clientCode: currentClientContext.client.ClientCode, advancedDownload})
            } catch(e) {
                tip_context.show('Something went wrong downloading');
                console.log(e);
            }
        }
        setDownloading(false)
    }

    async function syncDepartments() {
        setDownloading(true)
        setOpenMore(false)
        if (currentClientContext.client) {
            try {
                await PosService.syncDepartments(currentClientContext.client.Id)
                tip_context.showSuccess('Departments synced successfully')
            } catch(e) {
                tip_context.show('Something went wrong syncing departments');
                console.log(e);
            }
        }
        setDownloading(false)
    }

    return <Paper elevation={2} sx={{height: 'auto'}}>
        <SectionTitle
            type="primary"
            label="Import Mapping"
            rightComponent={
                <Tooltip title="Download Template">
                    {downloading || !currentClientContext.client ? 
                        <LoadingButton loading size="small" sx={{color: 'white'}}>
                            <DownloadRoundedIcon />
                        </LoadingButton>
                    :
                        (!isAdmin ? 
                            <IconButton component="button" onClick={downloadPosExample} sx={{color: 'white'}}>
                                <DownloadRoundedIcon />
                            </IconButton>
                        : 
                            <>
                                <IconButton onClick={e => { setOpenMore(true) }} ref={more_element}>
                                    <MoreVertOutlinedIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <Menu
                                    open={openMore}
                                    anchorEl={more_element.current}
                                    onClose={e => { setOpenMore(false) }}
                                >
                                    <MenuItem onClick={e => { downloadPosExample(false) }}>Download Basic Mapping</MenuItem>
                                    <MenuItem onClick={e => { downloadPosExample(true) }}>Download Advanced Mapping</MenuItem>
                                    <MenuItem onClick={e => { syncDepartments() }}>Sync Departments from Staging</MenuItem>
                                </Menu>
                            </>
                        )
                    }
                </Tooltip>
            }
        />
        {isAdmin ? 
            <Box sx={{p:1.5}}>
                <Box>Client ID: {currentClientContext.client?.Id}</Box>
                    <>
                        <Box
                            component="input"
                            type='file'
                            name="data"
                            sx={{display:'none'}}
                            ref={fileInputRef}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                            onChange={uploadClientPos}
                            onClick={(event)=> {
                                event.target.value = null
                            }}
                        ></Box>
                        <LoadingButton
                            loading={uploading}
                            fullWidth
                            size="large"
                            startIcon={<CloudUploadRoundedIcon />}
                            onClick={chooseFile}
                            sx={css.uploadButton}
                        >Upload</LoadingButton>
                    </>
            </Box>
        : null}
    </Paper>
}

const css = {
    uploadButton: {
        py: 2,
        border: '1px dashed transparent',
        borderColor: 'primary.main',
        borderRadius: 2,
        mt: 2,
    },
}

export default ClientPosMappingUpload