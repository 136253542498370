import Request from "./Request";

const FlightService = {

    async getFlightJobs() {
        return await Request.get('/api/flight-jobs')
    },

    async createFlightJob(data) {
        return await Request.post('/api/flight-jobs', data)
    },

    async updateFlightJob(flight_job_id, data) {
        console.log('data', data)
        return await Request.post(`/api/flight-jobs/${flight_job_id}`, data)
    },

    async deleteFlightJob(flight_job_id) {
        return await Request._delete(`/api/flight-jobs/${flight_job_id}`)
    },

    async activateFlightJob(flight_job_id) {
        return await Request.post(`/api/flight-jobs/${flight_job_id}/activate`)
    },

    async runFlightJob(flight_job_id, data) {
        return await Request.post(`/api/flight-jobs/${flight_job_id}/run`, data)
    },

    async executeFlightJob(flight_job_log_id) {
        const key = 'scHzcX1L4ZFjj5exMg57dB1aq1HtF0';
        return await Request.post(`/api/flight-jobs/${flight_job_log_id}/execute?key=${key}`);
    },

    async executeDailyLightSpeedJob(flight_job_log_id) {
        const key = 'scHzcX1L4ZFjj5exMg57dB1aq1HtF0';
        return await Request.post(`/api/flight-jobs/execute/lightspeed/${flight_job_log_id}?key=${key}`);
    },
    async uploadDailyLightspeedJob() {
        const key = 'scHzcX1L4ZFjj5exMg57dB1aq1HtF0';
        return await Request.post(`/api/flight-jobs/execute/lightspeed?key=${key}`);
    },

    async deactivateFlightJob(flight_job_id) {
        return await Request.post(`/api/flight-jobs/${flight_job_id}/deactivate`)
    },

    async getFlightJobLogs() {
        return await Request.get('/api/flight-job-logs')
    },

    /**
     * Get recent flight job logs.
     * 
     * @returns {Promise}
     */
    async getRecentFlightJobLogs() {
        return await Request.get('/api/flight-job-logs/recent')
    },

    /**
     * Get flight job logs for client
     * 
     * @returns {Promise}
     */
    async getFlightJobLogsForClient(clientId) {
        return await Request.get(`/api/clients/${clientId}/flight-job-logs`)
    },

    /**
     * Get flight job log by id
     * 
     * @param {Number} flightJobLogId
     * @param {Array} relationsToLoad
     * 
     * @returns {Promise}
     */
    async getFlightJobLogById({ flightJobLogId, relationsToLoad = [] }) {
        return await Request.get(`/api/flight-job-log/${flightJobLogId}`, { with: relationsToLoad.join(',') })
    },

    /**
     * Get flight job settings for client
     * @returns {Promise}
     */
    async getFlightJobSettingsForClient(clientId) {
        return await Request.get(`/api/clients/${clientId}/flight-job`)
    },

    async broadcastJob() {
        const key = 'scHzcX1L4ZFjj5exMg57dB1aq1HtF0';
        return await Request.post(`/api/flight-jobs/broadcast?key=${key}`)
    },

    /**
     * Get all flight clients.
     * 
     * @returns {Promise}
     */
    async getFlightClients() {
        return await Request.get('/api/flight-clients')
    },
}

export default FlightService