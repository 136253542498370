import {useState, useEffect} from 'react'
import {
  Navigate,
  useNavigate
} from "react-router-dom";
import {
	Container,
	Box,
	TextField,
	Button,
	Grid,
	Typography,
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Chip,
	Stack,
	Skeleton,
} from '@mui/material'
import {
	LoadingButton
} from '@mui/lab'
import DeleteIcon from '@mui/icons-material/Delete';
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'
import SectionTitle from '../../components/common/SectionTitle'
import _ from 'underscore'
import TipContext from '../../providers/GlobalTip'
import AuthContext from '../../providers/AuthProvider'
import PermissionUserItem from '../../components/roles/PermissionUserItem'
import UserModel from '../../models/User'
import AssignNewAdminUser from '../../components/roles/AssignNewAdminUser'

const jss = {
	permissionSection: {
		py: 2,
	},
	roleFormSection: {
	},
}

function UserRoles(props) {
	const auth_context = AuthContext.useAuth()
	const messageContext = TipContext.useMessage()
	const [roles, setRoles] = useState([])
	const [admin_users, setAdminUsers] = useState()
	const [current_role, setCurrentRole] = useState('ADD')
	const [current_role_name, setCurrentRoleName] = useState('')
	const [role_creating, setRoleCreating] = useState(false)
	const [show_assign_new_user, setShowAssignNewUser] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if( !auth_context.checkPermission('admin-user-setting') ) {
			navigate('/')
			return
		}
		loadAdminRoles()
		loadAdminUsers()
	}, [])

	async function loadAdminRoles() {
		const _roles = await RoleService.getAdminRoles()
		setRoles(_roles)
	}

	async function loadAdminUsers() {
		const _users = await UserService.getAdminUsers()
		setAdminUsers(_users.map(item => {return new UserModel(item)}))
	}	

	function handleCurrentRoleChange(e) {
		setCurrentRole(e.target.value)
		if( e.target.value=='ADD' ) {
			setCurrentRoleName('')
		} else {
			const _roles = _.indexBy(roles, 'id')
			setCurrentRoleName(_roles[e.target.value].name)
		}
	}

	function handleCurrentRoleNameChange(e) {
		setCurrentRoleName(e.target.value)
	}

	async function roleSubmit() {
		try {
			setRoleCreating(true)
			let origin_roles = roles
			if( current_role=='ADD' ) {
				const new_role = await RoleService.createRole(current_role_name, 'ADMIN')
				messageContext.showSuccess('Create successfully')
				origin_roles.push(new_role)
			} else {
				const updated_role = await RoleService.updateAdminRole(current_role, current_role_name)
				messageContext.showSuccess('Update successfully')
				origin_roles = origin_roles.map(item => {
					if( item.id == updated_role.id ) {
						return updated_role
					} else {
						return item
					}
				})
			}
			setRoles(origin_roles)
			setRoleCreating(false)
		} catch(e) {
			setRoleCreating(false)
			messageContext.show(e.response?.data.message)
		}
	}

	function addNetAdminUser() {
		setShowAssignNewUser(true)
	}

	function reloadAllUsers(e) {
		loadAdminUsers()
		setShowAssignNewUser(false)
	}

	return <Box sx={jss.permissionSection}>
		<Paper elevation={2}>
			<SectionTitle label="User Roles"/>
			<Box sx={{p:2}}>
				{auth_context.checkPermission('role-create') && 
					<Box sx={jss.roleFormSection}>
						<FormControl sx={{width: 200}} size="small">
						  <InputLabel>Roles</InputLabel>
						  <Select
						    value={current_role}
						    label="Role"
						    onChange={handleCurrentRoleChange}
						  >
						    <MenuItem value='ADD'>{`<NEW ROLE>`}</MenuItem>
						    {roles.map(item => {
						    	return <MenuItem key={`role_item_${item.id}`} value={item.id} >{item.name}</MenuItem>
						    })}
						  </Select>
						</FormControl>
						<TextField
							label=""
							sx={{ml:1}}
							size="small"
							value={current_role_name}
							onChange={handleCurrentRoleNameChange}
						/>
						<LoadingButton
							sx={{ml:1, height:38}}
							variant="contained"
							color="primary"
							loading={role_creating}
							onClick={roleSubmit}
							disabled={!current_role_name}
						>
							{current_role=='ADD' ? 'Add Role' : 'Update Role'}
						</LoadingButton>
					</Box>
				}
				<TableContainer sx={{mt: 2}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>User</TableCell>
								<TableCell>Roles</TableCell>
								<TableCell>Add Role</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{admin_users && admin_users.map(_user => {
								return <PermissionUserItem key={`admin_user_${_user.id}`} user={_user} roles={roles}/>
							})}	
							{!admin_users &&
								<>
									<TableRow>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
									</TableRow>
									<TableRow>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
										<TableCell><Skeleton sx={{height: 30}}/></TableCell>
									</TableRow>
								</>
							}
							{show_assign_new_user && 
								<AssignNewAdminUser roles={roles} userAdded={reloadAllUsers}/>
							}
						</TableBody>
					</Table>
				</TableContainer>
				<Box sx={{mt: 2}}>
					<LoadingButton
						variant="contained"
						color="primary"
						onClick={addNetAdminUser}
					>
						Assign User To Role
					</LoadingButton>
				</Box>
			</Box>
		</Paper>
	</Box>
}

export default UserRoles